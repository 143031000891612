import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton, SecondaryButton } from '../../components';
import { checkIsOldCar } from '../../util/dates';
import { getTransitButtonLabelId } from './transitionHelper';
import { flatten } from 'lodash';
import {
  isNeedCustomerDropOffAction,
  isNeedCustomerPickUpAction,
  isNeedProviderDropOffAction,
  isNeedProviderPickUpAction,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  txIWaitingConfirmPickup,
} from '../../util/transaction';

import css from './TripPanel.css';
import get from 'lodash/get';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  EVENT_CONVERSION_BOOKING_DECLINED,
  EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED,
} from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { formatMoney } from '../../util/currency';
import LockBoxSection from './LockBoxSection';
import DropOffCheckList from './DropOffCheckList';
import AlertBox from '../AlertBox/AlertBox';
import TripInstructionsSlider from './TripInstructionsSlider';
import { useDispatch } from 'react-redux';
import { nextPickStep, resetSteps } from '../PickAndDropPanel/ProgressBar/ProgressBar.duck';
import { removeStateFromLocalStorage } from '../../util/localStorageHelper';
import { lockShuCar } from '../../util/notification';

const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
                             userId,
                             listingId,
                             guestName,
                             pickUpDate,
                             dropOffDate,
                             plateNumber,
                             chasisNumber,
                             isOldCar,
                             transactionId,
                           }) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

const ButtonsSection = ({
                          currentOdometerReading,
                          dlGoTripDistanceInProgress,
                          fuelPrice,
                          declineInProgress,
                          declineButtonName,
                          transaction,
                          acceptInProgress,
                          acceptSaleError,
                          declineSaleError,
                          acceptButtonName,
                          currentUser,
                          onDeclineSale,
                          declineSaleGTM,
                          onAcceptSale,
                          acceptSaleGTM,
                          currentPhotos,
                          activePhotoSection,
                          exteriorPhotos,
                          selfieWithId,
                          interiorPhotos,
                          odometerPhotos,
                          fuelGaugePhotos,
                          fuelReceiptPhotos,
                          pickupProviderPhoto,
                          otherPhotos,
                          otherProviderPhotos,
                          onTransit,
                          showSaleButtons,
                          isShuEnabledCar,
                          showPickUpSection,
                          transitInProgress,
                          isCustomer,
                          isProvider,
                          allTripPhotos,
                          showDropOffSection,
                          isPickUp,
                          isDropOff,
                          isDrivelahGo,
                          onPushEventGTMPickUpDropOff,
                          ownListing,
                          listing,
                          onEstimateFuelBreakdown,
                          odometerStartDataStateUp,
                          odometerEndDataState,
                          shouldShowPickUpSection,
                          shouldShowDropOffSection,
                          isOdometerStartDataValid,
                          isOdometerEndDataValid,
                          estimateBreakdownInProgress,
                          initiateOrderInProgress,
                          handleCardPaymentInProgress,
                          confirmPaymentInProgress,
                          savePaymentMethodInProgress,
                          payForFuelSubmit,
                          intl,
                          setDropOffMarker,
                          fuelNotificationWithoutCharge,
                          getDlGoDistance,
                          onUpdateListingOdometerData,
                          onFuelNotificationFor500,
                          isNewPickUpDropSection,
                          isLimitedUser,
                          isPickUpByAdminButtonEnabled,
                          isLockboxActive,
                          transitionMaybe,
                          transitionAdminMaybe,
                          setShowViewDetails,
                          showViewDetails,
                          showStartTripButton,
                          showEndTrip,
                          requirePickupLocation,
                          requireDropoffLocation,
                          distanceFromPickUp,
                          isTimeToShowStartTripButton,
                          isUserLocationAvailable,
                          showStartTripStep,
                          currentRating,
                          lockBoxCode,
                          findDeviceByType,
                          onLockboxDataChange,
                          cleanlinessScore,
                          dropOffSentAtByAdmin,
                          customDropOffReadingByAdmin,
                          timeZone,
                          pickUpOdometer
                        }) => {
  const dispatch = useDispatch();
  const [showDropOffChecklist, setShowDropOffChecklist] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPickUpModal, setShowPickUpModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const showAlertMessage = () => {
    setShowAlert(false);

    setTimeout(() => {
      setShowAlert(true);
    }, 0);
  };
  let fuelSpeculativeTransaction = null;
  const buttonsDisabled = acceptInProgress || declineInProgress;
  const customerPickUpAction = showPickUpSection && isCustomer && isNeedCustomerPickUpAction(transaction);
  const customerDropOffAction = showDropOffSection && isCustomer && isNeedCustomerDropOffAction(transaction);
  const providerPickUpAction = showPickUpSection && isProvider && isNeedProviderPickUpAction(transaction);
  const providerDropOffAction = showDropOffSection && isProvider && isNeedProviderDropOffAction(transaction);
  const customerPickUpInteriorAction = showPickUpSection && isCustomer && isDrivelahGo && isNeedCustomerDropOffAction(transaction);
  const photoObjects = isDrivelahGo && transaction &&
  transaction.attributes &&
  transaction.attributes &&
  transaction.attributes.metadata &&
  transaction.attributes.metadata.photoObjects ?
    transaction.attributes.metadata.photoObjects :
    transaction.attributes.protectedData.photoObjects;
  const photoObjectsFlat = photoObjects ? flatten(photoObjects) : [];
  const odometerPhotosObject = photoObjectsFlat ? photoObjectsFlat.find((photo) => photo.odometerPhotos) : [];
  let odometerStartDataState = odometerPhotosObject && parseInt(odometerPhotosObject.odometerStartData);
  if (!odometerStartDataState) {
    odometerStartDataState = odometerStartDataStateUp;
  }
  const isFuelInclusion = get(transaction, 'attributes.protectedData.isFuelInclusion', null)
    || get(transaction, 'attributes.metadata.isFuelInclusion', null)
  ;
  const validTransaction = transaction && transaction.customer;
  // Removed the unverified check as admin can accept the booking
  const acceptAllow = validTransaction

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const guestIdentityIsNotVerify = !acceptAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const numberActivePhotoSection =
    activePhotoSection && activePhotoSection.filter(i => i === true).length;
  const isActivePhotoSection = isCustomer ? numberActivePhotoSection > 0 : true;
  const allCurrentTripPhotos = [...currentPhotos, ...allTripPhotos];
  const currentExteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'exteriorPhotos');
  const currentInteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'interiorPhotos');
  const currentOdometerPhotos = allCurrentTripPhotos.filter(p => p.type === 'odometerPhotos');
  const currentFuelGaugePhotos = allCurrentTripPhotos.filter(p => p.type === 'fuelGaugePhotos');
  const currentSelfieWithId = allCurrentTripPhotos.filter(p => p.type === 'selfieWithId');
  const currentOtherPhotos = allCurrentTripPhotos.filter(p => p.type === 'otherPhotos');
  const dlGoExteriorPhotos = flatten(allTripPhotos).filter(i => 'exteriorPhotos' in i);
  const dlGoSelfieWithIdPhotos = flatten(allTripPhotos).filter(i => 'selfieWithId' in i);

  console.log("allCurrentTripPhotos", allCurrentTripPhotos)
  let validPhotoSection = false;
  if (!isDrivelahGo && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true)
    && (currentOdometerPhotos && currentOdometerPhotos.length >= 1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1)
  ) {
    validPhotoSection = true;
  }

  //based on new design
  if(!isDrivelahGo && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  ) {
    validPhotoSection = true;
  }


  if(isDrivelahGo && isPickUp && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)
    && (currentSelfieWithId && currentSelfieWithId.length >=1)) {
    validPhotoSection = true;
  }

  //based on new design
  if(isDrivelahGo && isPickUp && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 8)) {
    validPhotoSection = true;
  }

  // if(isDrivelahGo && isPickupRequested
  //   && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
  //   && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  // ) {
  //   validPhotoSection = true;
  // }

  if(isLimitedUser && isDrivelahGo && isPickUp && !isNewPickUpDropSection &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 8  &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    isDropOff &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 8 &&
    (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true) &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    showPickUpSection &&
    isDropOff &&
    (!isLimitedUser ? (currentInteriorPhotos && currentInteriorPhotos.length >= 1) : true) &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (isLimitedUser && isDropOff && !dropOffSentAtByAdmin) {
    validPhotoSection = false
  }

  if (isLimitedUser && dropOffSentAtByAdmin) {
    console.log('IF > (isLimitedUser && dropOffSentAtByAdmin) >', { odometerPhotos, customDropOffReadingByAdmin });

    // Default to true when the user is limited and drop-off sent by admin is provided.
    validPhotoSection = true;

    // Condition 1: Neither odometerPhotos nor customDropOffReadingByAdmin provided
    if (!(odometerPhotos.length !== 0) && !customDropOffReadingByAdmin) {
      validPhotoSection = true;
      console.log('Condition 1 > No photos or custom reading uploaded');
    }

    // Condition 2: Either odometerPhotos or customDropOffReadingByAdmin is provided
    if (((odometerPhotos.length !== 0) && !customDropOffReadingByAdmin) || (!(odometerPhotos.length !== 0) && customDropOffReadingByAdmin)) {
      validPhotoSection = false;
      console.log('Condition 2 > Either photo or custom reading uploaded');
    }

    // Condition 3: Both odometerPhotos and customDropOffReadingByAdmin provided
    if ((odometerPhotos.length !== 0) && customDropOffReadingByAdmin) {
      validPhotoSection = true;
      console.log('Condition 3 > Both photo and custom reading uploaded');
    }
  }

  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isEligbleForStartCar =
    isShuEnabledCar &&
    (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE) ;

  let isStartCar = isHost && isEligbleForStartCar;

  if(isShuEnabledCar && !isHost && (transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED)) {
    isStartCar = true;
  }

  const isEligibleForPushConfig =
    isShuEnabledCar &&
    (transitionMaybe == TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED);
  const isShuCar = !isHost && isEligibleForPushConfig;
  const cleanlinessScoreData = cleanlinessScore || [];

  let isStopCar = ((TRANSITION_CONFIRM_DROP_OFF == transitionMaybe ) && isShuEnabledCar && isHost);

  if(isShuEnabledCar && !isHost && TRANSITION_REQUEST_DROP_OFF_DLGO == transitionMaybe) {
    isStopCar = true;
  }
  const getFirstRelevantPhotoNote = (data, type) => {
    const trip = isPickUp;
    for (let photoGroup of data) {
      for (let photo of photoGroup) {
        if (photo[type] && photo.isPickUp === trip) {
          return photo.note;
        }
      }
    }
    return null;
  };

  const updateCurrentOdometerReading = () => {
    if (isCustomer && isFuelInclusion) {
      if (isPickUp) {
        const odometerStartValue = get(odometerPhotos, '[0].odometerStartData', null);
        if (odometerStartValue) {
          onUpdateListingOdometerData(listing.id.uuid, odometerStartValue);
        }
      } else if (isDropOff) {
        const odometerEndValue = get(odometerPhotos, '[0].odometerEndData', null);
        if (odometerEndValue) {
          onUpdateListingOdometerData(listing.id.uuid, odometerEndValue);
        }
      }
    }
  };

  const sendFuelNotificationFor500 = fuelObj => {
    if (Object.keys(fuelObj).length) {
      const bookingStart = get(transaction, 'booking.attributes.displayStart', null);
      const bookingEnd = get(transaction, 'booking.attributes.displayEnd', null);
      const tripDays = moment(bookingEnd).diff(moment(bookingStart), 'days');
      if ((fuelObj.distanceTravelled / Math.ceil(tripDays)) > 500) {
        onFuelNotificationFor500(transaction.id.uuid);
      }
    }
  };

  const handlePrimaryButtonClick = (transaction, ownListing, selectedTransition, dlGoTripDistance = null)  => {
    // selectedTransition is used when multiple states are available.
    const singleTransition = (selectedTransition || transitionMaybe);
    let fuelObj = {};
    if (fuelSpeculativeTransaction) {
      if (odometerEndDataState && odometerStartDataState) {
        fuelObj = {
          distanceTravelled: odometerEndDataState - odometerStartDataState,
          fuelCharge: {
            totalDistance: odometerEndDataState - odometerStartDataState,
            payoutTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payoutTotal, 1),
            payinTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payinTotal, 1),
          },
        };
      }
      if (dlGoTripDistance) {
        fuelObj = {
          distanceTravelled: dlGoTripDistance,
          fuelCharge: {
            totalDistance: dlGoTripDistance,
            payoutTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payoutTotal, 1),
            payinTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payinTotal, 1),
          },
        };
      }
    }
    const dataObject = {
      currentTransaction: transaction,
      pickupSentAt: isPickUp ? moment.tz(timeZone).toISOString() : null,
      dropoffSentAt: isDropOff
        ? isLimitedUser
          ? dropOffSentAtByAdmin
            ? moment.tz(dropOffSentAtByAdmin, timeZone).toISOString()
            : moment.tz(timeZone).toISOString()
          : moment.tz(timeZone).toISOString()
        : null,
      pickupFrom: isPickUp ? (isLimitedUser ? "Admin" : "Web") : null,
      dropoffFrom: isDropOff ? (isLimitedUser ? "Admin" : "Web") : null,
      ...fuelObj,
    };
    onPushEventGTMPickUpDropOff(transaction, transitionMaybe);

    const getPreviousPhotos = (transaction) => {
      return transaction &&
        transaction.attributes &&
        transaction.attributes.protectedData &&
        transaction.attributes.protectedData.photoObjects  || []
    }

    const previousPhotos = getPreviousPhotos(transaction)

    console.log('Handle DropOff Button Click >> previousPhotos >>', previousPhotos);


    const getTypePhotos = (type) => {
      return (previousPhotos || []).find((photos) => {
        return (photos && photos.length && photos[0][type] )
      }) || []
    }

    const allPhotoKeys = ['exteriorPhotos', 'interiorPhotos', 'odometerPhotos', 'fuelGaugePhotos', 'pickupProviderPhoto', 'fuelReceiptPhotos']

    const sections = isProvider
      ? ['providerPickup']
      : ['exterior', 'interior', 'odometer', 'fuel'];

    if (!isProvider && !isPickUp){
      sections.push('fuelReceipt');
    }

    let currentPhotos = [];
    activePhotoSection.map((section, index) => {
      if (section === true) {
        if (sections[index] === 'exterior') {
          currentPhotos = [...currentPhotos, exteriorPhotos];
        }
        if (sections[index] === 'selfieWithId') {
          currentPhotos = [...currentPhotos, selfieWithId];
        }
        if (sections[index] === 'interior') {
          currentPhotos = [...currentPhotos, interiorPhotos];
        }
        if (sections[index] === 'odometer') {
          currentPhotos = [...currentPhotos, odometerPhotos];
        }
        if (sections[index] === 'fuel') {
          currentPhotos = [...currentPhotos, fuelGaugePhotos];
        }
        if (sections[index] === 'providerPickup') {
          currentPhotos = [...currentPhotos, pickupProviderPhoto];
        }
        if (sections[index] === 'fuelReceipt'){
          currentPhotos = [...currentPhotos, fuelReceiptPhotos]
        }
      }
    });

    const combinedPhotos = allPhotoKeys.map((photoKey) => {
      const previous =  getTypePhotos(photoKey);
      const current = currentPhotos.find((record) => record && record[0] && record[0][photoKey]) || []
      return [...previous , ...current]
    }).filter((record) => Boolean(record) && Boolean(record.length));
    const currentOdometerReading = getFirstRelevantPhotoNote(currentPhotos, 'odometerPhotos');
    const currentFuelGaugeReading = getFirstRelevantPhotoNote(currentPhotos, 'fuelGaugePhotos');

    if (isLimitedUser && !isDropOff) {
      if (isPickUp && (currentOdometerReading == null || currentOdometerReading == 0)) {
        setAlertMessage('Please enter the odometer reading');
        showAlertMessage()
        return;
      }
      if (isDropOff && currentOdometerReading <= pickUpOdometer) {
        setAlertMessage('Odometer reading cannot be less than at pickup.');
        showAlertMessage()
        return;
      }
      if (currentFuelGaugeReading == null || currentFuelGaugeReading == 0) {
        setAlertMessage('Fuel gauge reading is required.');
        showAlertMessage()
        return;
      }
    }

    const allPhotosDropOff = combinedPhotos;

    if (
      txIWaitingConfirmPickup(transaction) &&
      (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
        transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
        transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ) &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;
      fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => {
          onTransit(
            transaction.id,
            isLimitedUser && transitionAdminMaybe ? transitionAdminMaybe: transitionMaybe,
            dataObject,
            [...allPhotosDropOff],
            data,
            isShuCar,
            isStartCar,
            null,
            null,
            isStopCar,
            cleanlinessScoreData,
            isDropOff,
          ).then(() => {
            updateCurrentOdometerReading();
            sendFuelNotificationFor500(fuelObj);
            setInProgress(false)
            removeStateFromLocalStorage(`retain_pickup_dropoff_state/${transaction.id.uuid}`);
            if (isCustomer && isPickUp && !isLimitedUser) {
              dispatch(nextPickStep());
            } else {
              shouldShowPickUpSection(false);
              shouldShowDropOffSection(false);
            }
            // setDropOffMarker(false);
          });
        });
    } else {
      onTransit(
        transaction.id,
        isLimitedUser && transitionAdminMaybe ? transitionAdminMaybe: transitionMaybe,
        dataObject,
        [...allPhotosDropOff],
        null,
        isShuCar,
        isStartCar,
        null,
        null,
        isStopCar,
        cleanlinessScoreData,
        isDropOff,
      ).then(() => {
        updateCurrentOdometerReading();
        sendFuelNotificationFor500(fuelObj);
        const apiData = {
          user_id: transaction.customer.attributes.profile.publicData.shuUserId,
          booking_id: transaction.id.uuid,
          listingId: transaction.listing.id.uuid,
          fleet_id: transaction.listing.attributes.publicData.license_plate_number,
        };
        console.log('is dropoff', isDropOff)

        if (isDropOff && isDrivelahGo) {
          lockShuCar(apiData).then(response => {
            console.log('Car got locked successfully:', response);
          }).catch(error => {
            console.error('Error in lockShuCar:', error);
          });
        }
        setInProgress(false)
        removeStateFromLocalStorage(`retain_pickup_dropoff_state/${transaction.id.uuid}`);
        if (isCustomer && isPickUp && !isLimitedUser) {
          dispatch(nextPickStep());
        } else {
          shouldShowPickUpSection(false);
          shouldShowDropOffSection(false);
        }
        // setDropOffMarker(false);
      });
    }
  };

  const dropOffForFuelInclusion = (params, transaction, ownListing, selectedTransition) => {
    onEstimateFuelBreakdown(params)
      .then((estimatedTx) => {
        fuelSpeculativeTransaction = estimatedTx;
        return payForFuelSubmit(estimatedTx);
      })
      .then(() => handlePrimaryButtonClick(transaction, ownListing, selectedTransition, params.dlGoTripDistance))
      .catch(() => {
        fuelNotificationWithoutCharge(transaction.id.uuid);
        handlePrimaryButtonClick(transaction, ownListing, selectedTransition);
      })
    ;
  };

  const handleLockBoxCar = () => {
    if(customerPickUpAction) {
      setShowPickUpModal(true)
    } else if(customerDropOffAction) {
      setShowDropOffChecklist(true)
    }
  }

  const handlePayForFuel = async (transaction, ownListing, selectedTransition) => {
    setInProgress(true)
    if (
      isFuelInclusion
      && isCustomer
      && isDropOff
    ) {
      const listingId = get(transaction, 'listing.id');
      const params = {
        listingId,
        fuelPrice,
      }
      if (!isDrivelahGo) {
        const dropOffOdometer = odometerPhotos[0].note ?? null;
        params.odometerStartDataState = parseInt(pickUpOdometer);
        params.odometerEndDataState = parseInt(dropOffOdometer);

        await dropOffForFuelInclusion(params, transaction, ownListing, selectedTransition);
      } else {
        getDlGoDistance(transaction.id.uuid)
          .then(async response => {
            params.dlGoTripDistance = response.data.distanceTravelled || 0;
            await dropOffForFuelInclusion(params, transaction, ownListing, selectedTransition);
          })
        ;
      }
    } else {
      return handlePrimaryButtonClick(transaction, ownListing, selectedTransition);
    }
  };

  const dropOffInProgress = !!transitInProgress || !!estimateBreakdownInProgress
    || dlGoTripDistanceInProgress
    || !!initiateOrderInProgress || !!handleCardPaymentInProgress
    || !!confirmPaymentInProgress || !!savePaymentMethodInProgress
  ;

  return (
    <div className={css.buttonSectionContainer}>
      <div className={css.buttonSectionInner}>
        {isProvider && showSaleButtons && (
          <div className={css.buttonSectionText}>
            <span>
              Declining too many bookings can have a negative impact on your positioning on search.
            </span>
          </div>
        )}
        {isProvider && showSaleButtons && (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonDisabled}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                onDeclineSale(transaction.id);
                const properties = createRawPropertiesForGTM({ props: currentUser });
                createConversionEvents(properties, EVENT_CONVERSION_BOOKING_DECLINED, 'click');
                declineSaleGTM();
              }}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </PrimaryButton>
            <SecondaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || !acceptAllow}
              className={css.buttonAccepted}
              id={acceptButtonName}
              name={acceptButtonName}
              onClick={() => {
                if (acceptAllow) {
                  onAcceptSale(transaction.id, currentUser);
                  const properties = createRawPropertiesForGTM({ props: currentUser });
                  createConversionEvents(properties, EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED, 'click');
                  acceptSaleGTM();
                }
              }}
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </SecondaryButton>
          </div>
        )}

        {/*{customerPickUpInteriorAction && !(interiorPhotoDlGo.length > 0) && !uploadInteriorSuccess && (*/}
        {/*  <div className={css.buttonSectionWrapper}>*/}
        {/*    <PrimaryButton*/}
        {/*      className={css.buttonAccepted}*/}
        {/*      inProgress={uploadInteriorPhotoProgress || dlGoTripDistanceInProgress}*/}
        {/*      disabled={transitInProgress || dlGoTripDistanceInProgress || !validPhotoSection || uploadInteriorPhotoProgress}*/}
        {/*      id={declineButtonName}*/}
        {/*      name={declineButtonName}*/}
        {/*      onClick={() => {*/}
        {/*        const sections = isProvider*/}
        {/*          ? ['providerPickup', 'providerOther']*/}
        {/*          : ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel', 'fuelReceipt', 'other'];*/}
        {/*        let currentPhotos = [];*/}
        {/*        activePhotoSection.map((section, index) => {*/}
        {/*          if (section === true) {*/}
        {/*            if (sections[index] === 'interior') {*/}
        {/*              currentPhotos = [...currentPhotos, interiorPhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'odometer') {*/}
        {/*              currentPhotos = [...currentPhotos, odometerPhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'fuel') {*/}
        {/*              currentPhotos = [...currentPhotos, fuelGaugePhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'fuelReceipt') {*/}
        {/*              currentPhotos = [...currentPhotos, fuelReceiptPhotos];*/}
        {/*            }*/}
        {/*            if (sections[index] === 'other') {*/}
        {/*              currentPhotos = [...currentPhotos, fuelGaugePhotos];*/}
        {/*            }*/}
        {/*          }*/}
        {/*        });*/}
        {/*        onUploadInteriorPhotoToMetadata(transaction.id, [...currentPhotos]);*/}
        {/*        shouldShowPickUpSection(false);*/}
        {/*        shouldShowDropOffSection(false);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <FormattedMessage id="TransactionPanel.sendInteriorPhotos" />*/}
        {/*    </PrimaryButton>*/}
        {/*  </div>*/}
        {/*)}*/}

        { (customerPickUpAction || customerDropOffAction) && !isNewPickUpDropSection ? (
          <>
            <div
              className={`${css.buttonSectionWrapper} ${isLimitedUser ? css.isLimitedUserWrapper : ''}`}>
              <PrimaryButton
                id={'submitButtonId'}
                className={css.buttonAccepted}
                type="submit"
                inProgress={transitInProgress}
                disabled={
                  transitInProgress ||
                  !validPhotoSection ||
                  (isLimitedUser && isPickUp && !isPickUpByAdminButtonEnabled)
                }
                onClick={() => isLockboxActive ? handleLockBoxCar() : handlePayForFuel(transaction, ownListing)}
              >
                <FormattedMessage
                  id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)}/>
              </PrimaryButton>
            </div>
            {showAlert && (
              <AlertBox
                title="Input error"
                message={alertMessage}
                type="error"
                autoCloseTime={2000}
              />
            )}
          </>
        ) : null}

        { (providerPickUpAction || providerDropOffAction) ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              id={'submitButtonId'}
              className={css.buttonAccepted}
              type="submit"
              inProgress={transitInProgress}
              disabled={transitInProgress || !isActivePhotoSection}
              onClick={() => {
                handlePayForFuel(transaction, ownListing)
              }}
            >
              <FormattedMessage id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)} />
            </PrimaryButton>
          </div>
        ) : null}

        { (showViewDetails &&  !isLimitedUser) || isCustomer && showStartTripButton ? (
          <>
            <div className={`${css.buttonSectionWrapper} ${css.footerContainerWrapper}`}>
              {!showViewDetails ? <div className={css.tripSliderWrapper}>
                <TripInstructionsSlider currentUser={currentUser} />
              </div> : <div className={css.tripSliderWrapper}>
              </div>}
              <div>
                <PrimaryButton
                  id={'submitButtonId'}
                  className={`${css.buttonAccepted} ${css.footerButton} ${css.disabled}`}
                  type="submit"
                  inProgress={transitInProgress}
                  disabled={
                    !(showEndTrip ||
                      // Pickup logic
                      (isPickUp &&
                        !showViewDetails &&
                        (
                          // If requirePickupLocation is true or does not exist, check distance
                          (requirePickupLocation !== false && distanceFromPickUp?.distance <= 250 && isTimeToShowStartTripButton && isUserLocationAvailable) ||
                          // If requirePickupLocation is false, skip distance check, but check the time condition
                          (requirePickupLocation === false && isTimeToShowStartTripButton && isUserLocationAvailable)
                        )
                      ) ||
                      // Dropoff logic
                      (isDropOff &&
                        !showEndTrip &&
                        (
                          // If requireDropoffLocation is true or does not exist, check distance
                          (requireDropoffLocation !== false && distanceFromPickUp?.distance <= 250 && isUserLocationAvailable) ||
                          // If requireDropoffLocation is false, skip distance check, but check the time condition
                          (requireDropoffLocation === false && isTimeToShowStartTripButton && isUserLocationAvailable)
                        )
                      )
                    )
                  }
                  onClick={() => {
                    if (showViewDetails) {
                      setShowViewDetails();
                      shouldShowPickUpSection(true);
                    } else {
                      showStartTripStep(true);
                      dispatch(resetSteps());
                    }
                  }}
                >
                  {isPickUp ? (showViewDetails ? "View details" : 'Start pickup') : 'Start dropoff'}
                </PrimaryButton>
              </div>
            </div>
          </>
        ) : null}

        { isNewPickUpDropSection ? (
          <div className={`${css.buttonSectionWrapper} ${css.buttonAlignment}`}>
            <PrimaryButton
              id={'submitButtonId'}
              className={`${css.buttonAccepted} ${css.pickAndDropBtn}` }
              type="submit"
              inProgress={transitInProgress || inProgress}
              disabled={isPickUp && !currentRating}
              onClick={() => { // Call fuel receipt func here
                handlePayForFuel(transaction, ownListing)
              }}
            >
              { isPickUp ? 'Complete pickup' : 'Complete dropoff' }
            </PrimaryButton>
          </div>
        ) : null}
      </div>

      {isLockboxActive &&
        <DropOffCheckList
          isOpen={showDropOffChecklist}
          onClose={() => setShowDropOffChecklist(false)}
          cancelButtonId={'dropOffChecklistCancel'}
          onConfirm={() => {
            setShowDropOffChecklist(false);
            handlePayForFuel(transaction, ownListing)
          }}
          lockBoxCode={lockBoxCode}
        />}

      { isLockboxActive && showPickUpModal &&
        <LockBoxSection
          listing = {listing}
          isPikUp = {true}
          findDeviceByType = {findDeviceByType}
          isOpenModal={showPickUpModal}
          onClose={() => setShowPickUpModal(false)}
          cancelButtonId={'dropOffChecklistCancel'}
          onConfirm={() => {
            setShowPickUpModal(false);
            handlePrimaryButtonClick(transaction, ownListing);
          }
          }
          onLockboxDataChange={onLockboxDataChange}
        />}
    </div>
  );
};

export default ButtonsSection;
