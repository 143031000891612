import { get } from 'lodash';
import {
  AVAILABILITY,
  DESCRIPTION,
  EVENT_LISTACAR_COMPLETED_AVAILABILITY,
  EVENT_LISTACAR_COMPLETED_CARDETAILS,
  EVENT_LISTACAR_COMPLETED_CARFEATURES,
  EVENT_LISTACAR_COMPLETED_CARINSTRUCTIONS,
  EVENT_LISTACAR_COMPLETED_CARRULES,
  EVENT_LISTACAR_COMPLETED_INSURANCE,
  EVENT_LISTACAR_COMPLETED_LOCATION,
  EVENT_LISTACAR_COMPLETED_NEXT_LEVEL,
  EVENT_LISTACAR_COMPLETED_ONBOARDING,
  EVENT_LISTACAR_COMPLETED_PICTURES,
  EVENT_LISTACAR_COMPLETED_PRICING, EVENT_LISTACAR_COMPLETED_SUBSCRIPTION,
  FEATURES,
  INSURANCE,
  LISTACAR_ABOUT_BUTTON_ID,
  LISTACAR_AVAILABILITY_BUTTON_ID,
  LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID,
  LISTACAR_CAR_RULES_BUTTON_ID,
  LISTACAR_FEATURES_BUTTON_ID,
  LISTACAR_INSURANCE_BUTTON_ID,
  LISTACAR_LOCATION_BUTTON_ID,
  LISTACAR_NEXT_LEVEL_BUTTON_ID,
  LISTACAR_ONBOARDING_CALL_BUTTON_ID,
  LISTACAR_PICTURES_BUTTON_ID,
  LISTACAR_PRICING_BUTTON_ID, LISTACAR_SUBSCRIPTION_BUTTON_ID,
  LOCATION,
  NEXT_LEVEL,
  ONBOARDING_CALL,
  PHOTOS,
  POLICY,
  PRICING,
} from './gtmConstants';
import * as translationEN from '../../translations/en.json';
import {
  getCurrentUser,
  getUserInformationGTM,
  setUserInformationGTM,
} from '../browserStorageHelper';
import { drivelahApiGet } from '../apiHelper';
import { getPUSHTELLResultPage } from '../emailNotify';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../urlHelpers';
import { createResourceLocatorString } from '../routes';
import config from '../../config';
import {
  INSTRUCTIONS,
  SUBSCRIPTION,
} from '../../components/EditListingWizard/EditListingWizardTab';
import { pushDataLayer } from './gtmHelpers';
import { logEvent, logEventViaApi } from '../amplitudeSdk';
import { getUserId } from '../amplitudeUser';

export const createRawPropertiesForGTM = ({
  props,
  pageName,
  button,
  listing,
  transaction = {},
  trip = {},
  search = {},
  pageURL,
}) => {
  const { currentUser, listing: listingProp, location = {} } = props;
  const page = {
    url: pageURL ? pageURL : location.pathname,
    name: pageName ? pageName : document.title,
  };
  const listingGTM =
    (get(listing, 'id') && listing) ||
    (get(listingProp, 'id') && listingProp) ||
    get(transaction, 'listing');
  const properties = {
    ui: {
      page,
      button,
    },
    currentUser: currentUser || getCurrentUser(),
    listing: listingGTM,
    transaction,
    trip,
    search,
  };
  return properties;
};

export const createRawPropertiesForGTMPromoLandingPage = ({
  props,
  pageName,
  button,
  listing,
  transaction = {},
  trip = {},
  search = {},
  pageURL,
}) => {
  const { currentUser = null, listing: listingProp, location = {} } = props || {};
  const page = {
    url: pageURL ? pageURL : location.pathname,
    name: pageName ? pageName : document.title,
  };
  const listingGTM =
    (get(listing, 'id') && listing) ||
    (get(listingProp, 'id') && listingProp) ||
    get(transaction, 'listing');
  const properties = {
    ui: {
      page,
      button,
    },
    currentUser: currentUser || getCurrentUser(),
    listing: listingGTM,
    transaction,
    trip,
    search,
  };
  return properties;
};

const parseEmptyInfoNull = data => {
  if (data === undefined) {
    return null;
  }
  return data;
};

const createButtonObject = ({ postition, submitButton, text, buttonId }) => {
  const { top, left } = postition || {};
  const { innerText } = submitButton || {};
  const buttonProperties = {
    text: text || innerText,
    id: buttonId,
    position: {
      left: left,
      top: top,
    },
  };
  return buttonProperties;
};

export const createButtonProperties = button => {
  const { buttonId, text } = button;
  const submitButton = document.getElementById(buttonId);
  const postition = submitButton && submitButton.getBoundingClientRect();
  return createButtonObject({ postition, submitButton, text, buttonId });
};

export const createUIProperties = ui => {
  const { page, button } = ui || {};
  const buttonProperties = button ? createButtonProperties(button) : null;
  return {
    page: page,
    button: buttonProperties,
  };
};

export const createPropertiesForDuplicatedButton = button => {
  const { buttonId: buttonName, text } = button;
  const submitButtonArray = document.getElementsByName(buttonName);
  let submitButton;

  for (let i = 0; i < submitButtonArray.length; i++) {
    if (submitButtonArray[i].offsetWidth > 0) {
      submitButton = submitButtonArray[i];
      break;
    }
  }
  const postition = submitButton && submitButton.getBoundingClientRect();
  return createButtonObject({ postition, submitButton, text, buttonId: buttonName });
};

export const createUIPropertiesForDuplicatedButton = ui => {
  const { page, button } = ui || {};
  const buttonProperties = button ? createPropertiesForDuplicatedButton(button) : null;
  return {
    page: page,
    button: buttonProperties,
  };
};

const getUserInformation = userId => {
  const userInfo = getUserInformationGTM(`USER_INFO_${userId}`);
  return userInfo;
};

const createUserDataObject = data => {
  const { attributes = {} } = data || {};
  const { profile } = attributes || {};
  const { protectedData = {}, metadata = {}, publicData = {} } = profile || {};
  const {
    intercomUserStat = {},
    smoveUser,
    smoveUserType,
    smoveEmailSent,
    smoveType3,
    smoveWelcomeEmailOpened,
  } = metadata || {};
  const userId = get(data, 'id.uuid', null);
  const firstName = get(attributes, 'profile.firstName', null);
  const userEmail = get(attributes, 'email', null);
  const lastName = get(attributes, 'profile.lastName', null);
  const { blockNo = '', building = '', city = '', country = '' } = protectedData || {};
  const streetName = get(protectedData, 'location.selectedPlace.address', '');
  const dateOfBirth = protectedData.dateOfBirth;
  const dateOfBirthDate = dateOfBirth
    ? new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day).toISOString()
    : null;
  const newVariant = getPUSHTELLResultPage();
  const utmParams = publicData['utm-params'] || {};
  const userInfo = {
    firstname: firstName || null,
    email: userEmail || null,
    userEmail: userEmail || null,
    smoveUser: smoveUser || null,
    smoveUserType: smoveUserType || null,
    smoveEmailSent: smoveEmailSent || null,
    smoveType3: smoveType3 || null,
    smoveWelcomeEmailOpened: smoveWelcomeEmailOpened || null,
    lastname: lastName || null,
    fullname: `${firstName} ${lastName}`,
    stripeID: get(data, 'stripeAccount.attributes.stripeAccountId', null),
    flexID: userId,
    hoststatus: parseEmptyInfoNull(intercomUserStat.host_verification_status),
    gueststatus: parseEmptyInfoNull(intercomUserStat.guest_verification_status),
    age: parseEmptyInfoNull(protectedData.age),
    address: `${blockNo} ${streetName} ${building} ${city} ${country}`,
    postalcode: parseEmptyInfoNull(protectedData.postalCode),
    postalDistrict: parseEmptyInfoNull(protectedData.postalDistrict),
    city: parseEmptyInfoNull(protectedData.city),
    country: parseEmptyInfoNull(protectedData.country),
    dateofbirth: dateOfBirthDate,
    photo: data.profileImage ? true : false,
    gender: parseEmptyInfoNull(protectedData.gender),
    phone: parseEmptyInfoNull(protectedData.phoneNumber),
    phoneverified: parseEmptyInfoNull(protectedData.phoneNumberVerified),
    residencyno: parseEmptyInfoNull(protectedData.residencyNumber),
    nricuploaded: intercomUserStat.nric_fin_status ? true : false,
    bookingacceptancerate: parseEmptyInfoNull(intercomUserStat.booking_acceptance_rate),
    responserate: parseEmptyInfoNull(intercomUserStat.booking_response_rate),
    signedupdate: parseEmptyInfoNull(attributes.createdAt),
    approvaldatehost: parseEmptyInfoNull(intercomUserStat.host_verified_date),
    approvaldateguest: parseEmptyInfoNull(intercomUserStat.guest_verified_date),
    status: attributes.banned ? 'banned' : 'opened',
    utm_source_final: utmParams.utm_source || null,
    utm_medium_final: utmParams.utm_medium || null,
    utm_campaign_final: utmParams.utm_campaign || null,
    utm_content_final: utmParams.utm_content || null,
    // UTM_source: null,
    // UTM_medium: null,
    // UTM_campaign: null,
    // CPA: null,
    // UTM_content: null,
    unsubscribedemail: null,
    appinstalls: null,
    successful_trips_guest_days: parseEmptyInfoNull(
      intercomUserStat.number_of_trips_day_completed_as_guest
    ),
    successful_trips_host: parseEmptyInfoNull(intercomUserStat.number_of_trips_success_as_host),
    successful_trips_host_days: parseEmptyInfoNull(
      intercomUserStat.number_of_trips_day_completed_as_host
    ),
    responsetime: parseEmptyInfoNull(intercomUserStat.average_booking_responded_time_as_host),
    bookingsaccepted_as_host: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_accepted_as_host
    ),
    bookingsaccepted_as_host_days: null,
    bookingaccepted_as_guest: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_accepted_as_guest
    ),
    bookingaccepted_as_guest_days: null,
    bookingsdeclined_as_host: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_declined_as_host
    ),
    bookingsdeclined_as_host_days: null,
    bookingdeclined_as_guest: null,
    bookingdeclined_as_guest_days: null,
    bookingsexpired_as_host: null,
    bookingsexpired_as_host_days: null,
    bookingsexpired_as_guest: null,
    bookingsexpired_as_guest_days: null,
    enquiriesreceived: parseEmptyInfoNull(intercomUserStat.number_of_enquiries_received),
    enquiriessent: parseEmptyInfoNull(intercomUserStat.enquiries_sent),
    messagessent_host: null,
    messagessent_guest: null,
    triphostcancel_host: null,
    triphostcancel_host_days: null,
    triphostcancel_guest: null,
    triphostcancel_guest_days: null,
    tripguestcancel_guest: null,
    tripguestcancel_guest_days: null,
    tripguestcancel_host: null,
    tripguestcancel_host_days: null,
    bookingfailed_guest: parseEmptyInfoNull(
      intercomUserStat.number_of_booking_requests_payment_failed_as_guest
    ),
    bookingfailed_guest_days: null,
    district: null,
    bookingsent_guest: parseEmptyInfoNull(intercomUserStat.booking_requests_sent),
    bookingrecd_host: parseEmptyInfoNull(intercomUserStat.booking_requests_received),
    email_verified: intercomUserStat.email_verification_status ? true : false,
    published_listings: parseEmptyInfoNull(intercomUserStat.number_of_published_listings),
    live_listings: parseEmptyInfoNull(intercomUserStat.number_of_live_listings),
    pending_listings: parseEmptyInfoNull(intercomUserStat.number_of_pending_listings),
    draft_listings: parseEmptyInfoNull(intercomUserStat.number_of_draft_listings),
    image: data.profileImage ? true : false,
    ID: userId ? true : false,
    Driving_license: intercomUserStat.driving_license_status ? true : false,
    referralcodeused: parseEmptyInfoNull(protectedData.referralCode),
    uniquereferralcode: userId,
    credits: parseEmptyInfoNull(metadata.creditBalance),
    intercomtags: intercomUserStat ? true : false,
    fbprofile: null,
    instaprofile: null,
    twitterprofile: null,
    linkedinprofile: null,
    browser: null,
    websessions: null,
    firstseen: null,
    lastseen: null,
    lastheardfrom: null,
    lastopenedemail: null,
    averagerating: null,
    numberofreviews: null,
  };
  if (userId) {
    setUserInformationGTM(`USER_INFO_${userId}`, userInfo);
  }
  return userInfo;
};

export const createUserHostGuestProperties = async (
  currentUser,
  transaction,
  userDiffActionTaker
) => {
  if (!currentUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');
  const transactionId = transaction.id && transaction.id.uuid;
  let customerId = get(transaction, 'customer.id.uuid');
  const providerId = get(transaction, 'provider.id.uuid');
  let customerInformation = getUserInformation(customerId);
  let providerInformation = getUserInformation(providerId);
  let userIsCustomer =
    (customerId === userId && !userDiffActionTaker) ||
    (customerId !== userId && userDiffActionTaker);

  if (customerInformation && providerInformation) {
    return {
      host: providerInformation,
      guest: customerInformation,
      user: userIsCustomer ? customerInformation : providerInformation,
    };
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/transaction-user?id=${userId}&uvk=${verificationKey}&transactionId=${transactionId}`
  );

  if (response.ok) {
    const jsonData = await response.json();
    const { data = {} } = jsonData || {};
    const { host = {}, guest = {} } = data || {};
    customerInformation = createUserDataObject(guest);
    providerInformation = createUserDataObject(host);
    customerId = guest.id && guest.id.uuid;
    userIsCustomer =
      (customerId === userId && !userDiffActionTaker) ||
      (customerId !== userId && userDiffActionTaker);
    return {
      host: providerInformation,
      guest: customerInformation,
      user: userIsCustomer ? customerInformation : providerInformation,
    };
  } else {
    console.error(`Error: ${response.status}`);
    return {};
  }
};

export const createHostAndGuestWithoutTransaction = async (currentUser, otherUser) => {
  if (!currentUser || !otherUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const otherUserId = otherUser.id && otherUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');

  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/user-host-guest-info?id=${userId}&uvk=${verificationKey}&otherUserId=${otherUserId}`
  );

  if (response.ok) {
    const jsonData = await response.json();
    const { data } = jsonData || {};
    const { user = {}, otherUser = {} } = data || {};
    const currentUserInformation = createUserDataObject(user);
    const otherUserInformation = createUserDataObject(otherUser);

    return {
      user: currentUserInformation,
      otherUser: otherUserInformation,
    };
  } else {
    console.error(`Error: ${response.status}`);
    return {};
  }
};

export const createExperimentDataProperties = async experimentData => {
  if (!experimentData) {
    return {};
  }
  let data = {};
  const experimentName = experimentData.experimentName;
  const variantName = experimentData.variantName;
  let experimentNameUser = 'AttractivenessScoreExperiment' + variantName;

  switch (experimentName) {
    case 'Attractiveness Score Experiment':
      data[experimentNameUser] = variantName;
      return data;
    case 'Home Page':
      data[experimentNameUser] = variantName;
      return data;
    default:
      return {};
  }
};

export const createUserProperties = async currentUser => {
  if (!currentUser) {
    return {};
  }
  const userId = currentUser.id && currentUser.id.uuid;
  const verificationKey = get(currentUser, 'attributes.profile.privateData.userVerificationKey');
  const userInformation = getUserInformation(userId);
  if (userInformation) {
    return {
      user: userInformation,
    };
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/gtm/user?id=${userId}&uvk=${verificationKey}`
    );
    if (response.ok) {
      const jsonData = await response.json();
      const { data } = jsonData || {};
      return {
        user: createUserDataObject(data),
      };
    } else {
      console.error(`Error: ${response.status}`);
      return {};
    }
  } catch (error) {
    console.error(`Error: ${error && error.message ? error.message : error}`);
    return {};
  }
};

export const createListingProperties = listing => {
  if (!listing) {
    return null;
  }
  const { attributes = {}, id = {} } = listing;
  const { publicData = {}, metadata = {}, privateData = {} } = attributes || {};
  const { pricing = {} } = publicData || {};

  const title = attributes.title;
  const pathToItem = createResourceLocatorString('ListingPage', routeConfiguration(), {
    id: id && id.uuid,
    slug: createSlug(title),
  });

  return {
    id: id && id.uuid,
    title: attributes.title,
    age: publicData.ageOfCar,
    url: `${config.canonicalRootURL}${pathToItem}`,
    create_date:
      attributes.createdAt instanceof Date
        ? attributes.createdAt.toISOString()
        : attributes.createdAt,
    approval_date: metadata.liveAt || null,
    status: attributes.state,
    brand_name: publicData.brandName,
    can_drive_to_malaysia: publicData.canDriveToMalaysia,
    model_name: publicData.modelName,
    insurance_type: publicData.insurance,
    company_owned: publicData.companyOwned ? publicData.companyOwned : 'no',
    date_of_registration: publicData.dateOfRegistration,
    kms_driven: publicData.drivenKm,
    engine_capacity: publicData.engineCapacity,
    fuel_type: publicData.fuelType,
    is_drivelah_go: !!metadata.isDrivelahGo,
    is_instantbooking: !!publicData.instantBooking,
    is_PHV: !!publicData.isPHV,
    delivery: publicData.delivery,
    registrationnumber: null,
    mileage_per_day: publicData.millagePerDay,
    chasis_number: privateData.chasisNumber,
    pics: null,
    is_tracking_device:
      privateData.trackingDeviceId && privateData.trackingDeviceId !== 'ADMIN FILL INFORMATION HERE'
        ? true
        : false,
    tracking_device_id: privateData.trackingDeviceId,
    slick_web_id: privateData.slickWebhookId,
    license_plate: publicData.license_plate_number,
    category: publicData.category,
    vehicle_type: publicData.vehicleType,
    successful_trips: publicData.numberTripDone,
    sucessful_trips_days: null,
    views: publicData.views,
    search_results: publicData.searchResults,
    bookings_received: null,
    bookings_received_days: null,
    booking_accepted: null,
    booking_accepted_days: null,
    trip_cancelled_host: null,
    trip_cancelled_host_days: null,
    trip_cancelled_guest: null,
    trip_cancelled_guest_days: null,
    enquiries_recvd: null,
    booking_declined: null,
    booking_declined_days: null,
    last30day_calupdated: null,
    last60day_calupdated: null,
    postalCode: publicData && publicData.location && publicData.location.postalCode,
    postaldistrict: publicData && publicData.postalDistrict,
    regularprice: attributes.price && attributes.price.amount,
    peakprice: pricing.peakPrice && pricing.peakPrice.amount,
    weeklydiscount: pricing.discount && pricing.discount.week,
    threedaydiscount: pricing.discount && pricing.discount.threeDays,
    monthlydiscount: pricing.discount && pricing.discount.month,
    reviews: metadata.reviews && metadata.reviews.averageRating,
    location: publicData.location && publicData.location.address,
  };
};

const createBookingFeesAndPromo = transaction => {
  const { attributes = {} } = transaction;
  const { lineItems = [] } = attributes || {};
  const price = {};
  lineItems.forEach(lineItem => {
    if (lineItem.code === 'line-item/customer-commission') {
      price.tripFees = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/units') {
      price.tripPrice = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/customer-promo') {
      price.totalDiscount = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/young-customer-commission') {
      price.youngDriverFees = lineItem.lineTotal.amount;
    }
  });
  return price;
};

export const createBookingProperties = (transaction = {}, listing) => {
  if (!transaction) {
    return {};
  }
  const bookingFeesAndPromo = createBookingFeesAndPromo(transaction);
  const { booking = {}, attributes: transactionAttributes = {}, id = {} } = transaction;
  const { attributes = {} } = booking || {};
  const { protectedData = {}, payinTotal = {} } = transactionAttributes || {};
  const { code } = protectedData || {};
  const tripDays = get(protectedData, 'days.regular') + get(protectedData, 'days.peak');
  const isDrivelahGo = get(listing, 'attributes.metadata.isDrivelahGo');
  const isInstantBooking = get(listing, 'attributes.publicData.instantBooking');
  const isDelivery = get(listing, 'attributes.publicData.delivery');
  const isExcessReduction = get(transaction, 'attributes.protectedData.isExcessReduction');
  const isExcessReduction2 = get(transaction, 'booking_vas_excess_2');
  const isFuelType = get(transaction, 'booking_vas_fuel');
  const booking_vas_excess = isExcessReduction ? true : false;
  const booking_vas_excess_2 = isExcessReduction2 ? true : false;
  const booking_vas_fuel = isFuelType ? true : false;
  const booking_vas_excess_from = get(transaction, 'booking_vas_excess_from');
  const booking_vas_excess_2_from = get(transaction, 'booking_vas_excess_2_from');
  const booking_vas_fuel_from = get(transaction, 'booking_vas_fuel_from');
  const booking_unverified = get(transaction, 'attributes.protectedData.isUnverified');
  const bookingProperties = {
    pickup_date:
      attributes.start && attributes.start instanceof Date
        ? attributes.start.toISOString()
        : attributes.start,
    pickup_time:
      attributes.displayStart && attributes.displayStart instanceof Date
        ? attributes.displayStart.toISOString()
        : attributes.displayStart,
    dropoff_date:
      attributes.end && attributes.end instanceof Date
        ? attributes.end.toISOString()
        : attributes.end,
    dropoff_time:
      attributes.displayEnd && attributes.displayEnd instanceof Date
        ? attributes.displayEnd.toISOString()
        : attributes.displayEnd,
    promo_credits: code ? false : true,
    guestcredits_value: code ? null : parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount),
    guestpromo_value: code ? parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount) : null,
    totaldiscount: code
      ? protectedData.discount
      : parseEmptyInfoNull(bookingFeesAndPromo.totalDiscount),
    promocode: parseEmptyInfoNull(code),
    tripprice: bookingFeesAndPromo.tripPrice,
    tripfees: bookingFeesAndPromo.tripFees,
    youngdriverfees: parseEmptyInfoNull(bookingFeesAndPromo.youngDriverFees),
    totalprice: payinTotal && payinTotal.amount,
    trip_days: tripDays,
    trip_id: id.uuid,
    instant_booking: !!isInstantBooking,
    DLGo: !!isDrivelahGo,
    vasDelivery: isDelivery ? true : false,
    booking_vas_excess: isExcessReduction ? true : false,
    booking_vas_excess_2,
    booking_vas_fuel,
    booking_vas_excess_from,
    booking_vas_excess_2_from,
    booking_vas_fuel_from,
    booking_unverified,
  };
  return bookingProperties;
};

const revertGeolocation = bounds => {
  if (!bounds || !bounds.ne || !bounds.sw) {
    return null;
  }
  const lat = (bounds.ne.lat + bounds.sw.lat) / 2;
  const lng = (bounds.ne.lng + bounds.sw.lng) / 2;
  const options = { params: { latlng: `${lat},${lng}` } };
  const url = 'google/coordinate-to-address';
  return drivelahApiGet(url, options);
};

export const getEventsByBucket = async (bucket) => {
  try {
    const result = await drivelahApiGet(`intercom/events?bucket=${bucket}`);
    return result;
  } catch (error) {
    console.error(error, 'get-events-by-bucket-fail', { bucket });
    throw new Error(error);
  }
};
export const signupBucketMapping = (eventProperties, props = {}) => {
  const segmentData = {};

  const { currentUser = {}, currentListing = {}, signupMode, role, signupCode, referralCode, button, host = {} } = props;

  const userProps = [
    'guest name', 'guest email', 'guest flexID', 'guest address', 'guest postalcode',
    'guest gueststatus', 'guest phone', 'guest miles club'
  ];
  const listingProps = [
    'listing title', 'listing id', 'listing age', 'listing created date', 'listing approval date',
    'listing status', 'listing brand name', 'listing can drive to malaysia', 'listing model name',
    'listing insurance type', 'listing date of registration', 'listing kms driven', 'listing engine capacity',
    'listing fuel type', 'listing is drivelah go', 'listing is instantbooking', 'listing is PHV',
    'listing registrationnumber', 'listing mileage per day', 'listing delivery', 'listing category',
    'listing vehicle type', 'listing successful trips', 'listing sucessful trips days', 'listing views',
    'listing search results', 'listing regularprice', 'listing peakprice', 'listing weeklydiscount',
    'listing monthlydiscount', 'listing rating', 'listing location', 'listing viewed source',
    'listing url', 'listing seats', 'listing attractivenessscore', 'listing pricescore', 'listing longterm',
    'listing availabilityscore', 'listing suburb', 'listing postal district', 'listing superhost',
    'listing submitted date', 'listing live date', 'listing is new', 'listing hourly enabled',
    'listing nearest stop', 'listing private insurer', 'listing bar', 'listing rms', 'listing caretaker',
    'listing picture score'
  ];
  const hostProps = [
    'host name', 'host email', 'host flexID', 'host address', 'host postalcode',
    'host gueststatus', 'host phone', 'host miles club'
  ];

  const getUserData = (propName, user) => {
    const userMapping = {
      'guest name': user.attributes && user.attributes.profile && user.attributes.profile.displayName,
      'guest email': user.attributes && user.attributes.email,
      'guest flexID': user.id && user.id.uuid,
      'guest address': user.attributes && user.attributes.profile && user.attributes.profile.publicData && user.attributes.profile.publicData.location && user.attributes.profile.publicData.location.selectedPlace && user.attributes.profile.publicData.location.selectedPlace.address,
      'guest postalcode': user.attributes && user.attributes.profile && user.attributes.profile.protectedData && user.attributes.profile.protectedData.postalCode,
      'guest gueststatus': user.attributes && user.attributes.profile && user.attributes.profile.publicData && user.attributes.profile.publicData.guestIdentityVerificationStatus,
      'guest phone': user.attributes && user.attributes.profile && user.attributes.profile.protectedData && user.attributes.profile.protectedData.phoneNumber,
      'guest miles club': user.attributes && user.attributes.profile && user.attributes.profile.publicData && user.attributes.profile.publicData.isPremiumUser
    };
    return userMapping[propName];
  };

  const getListingData = (propName, listing) => {
    const listingMapping = {
      'listing title': listing.attributes && listing.attributes.title,
      'listing id': listing.id && listing.id.uuid,
      'listing age': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.ageOfCar,
      'listing created date': listing.attributes && listing.attributes.createdAt,
      'listing approval date': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.liveAt,
      'listing status': listing.attributes && listing.attributes.state,
      'listing brand name': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.brandName,
      'listing can drive to malaysia': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.canDriveToMalaysia,
      'listing model name': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.modelName,
      'listing insurance type': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.insurance,
      'listing date of registration': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.dateOfRegistration,
      'listing kms driven': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.drivenKm,
      'listing engine capacity': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.engineCapacity,
      'listing fuel type': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.fuelType,
      'listing is drivelah go': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.isDrivelahGoSorting,
      'listing is instantbooking': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.instantBooking,
      'listing is PHV': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.isPHV,
      'listing registrationnumber': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.license_plate_number,
      'listing mileage per day': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.millagePerDay,
      'listing delivery': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.delivery,
      'listing category': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.category,
      'listing vehicle type': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.vehicleType,
      'listing successful trips': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.numberTripDone,
      'listing sucessful trips days': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.tripDays,
      'listing views': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.views,
      'listing search results': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.searchResults,
      'listing regularprice': listing.attributes && listing.attributes.price && listing.attributes.price.amount,
      'listing peakprice': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.pricing && listing.attributes.publicData.pricing.peakPrice,
      'listing weeklydiscount': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.pricing && listing.attributes.publicData.pricing.discount && listing.attributes.publicData.pricing.discount.week,
      'listing monthlydiscount': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.pricing && listing.attributes.publicData.pricing.discount && listing.attributes.publicData.pricing.discount.month,
      'listing rating': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.reviews && listing.attributes.metadata.reviews.averageRating,
      'listing location': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.location && listing.attributes.publicData.location.address,
      'listing viewed source': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.viewedSource,
      'listing url': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.url,
      'listing seats': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.peopleNumber,
      'listing attractivenessscore': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.attractiveScore,
      'listing pricescore': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.priceScore,
      'listing longterm': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.longTerm,
      'listing availabilityscore': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.availabilityScore,
      'listing suburb': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.location && listing.attributes.publicData.location.neighbour,
      'listing postal district': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.postalDistrict,
      'listing superhost': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.isSuperHost,
      'listing submitted date': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.listingSubmittedAt,
      'listing live date': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.liveAt,
      'listing is new': listing.attributes && listing.attributes.metadata && listing.attributes.metadata.isNewCar,
      'listing hourly enabled': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.hourlyBooking,
      'listing nearest stop': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.nearestStop,
      'listing private insurer': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.privateInsurer,
      'listing bar': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.booking_acceptance_rate,
      'listing rms': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.isRMS,
      'listing caretaker': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.isCaretaker,
      'listing picture score': listing.attributes && listing.attributes.publicData && listing.attributes.publicData.pictureScore
    };
    return listingMapping[propName];
  };

  eventProperties.forEach(event => {
    const propertyName = event.Property_Name;

    if (userProps.includes(propertyName) && currentUser) {
      if (!segmentData.guest) segmentData.guest = {};
      segmentData.guest[propertyName] = getUserData(propertyName, currentUser);
    } else if (listingProps.includes(propertyName) && currentListing) {
      if (!segmentData.listing) segmentData.listing = {};
      segmentData.listing[propertyName] = getListingData(propertyName, currentListing);
    } else if (hostProps.includes(propertyName) && host) {
      if (!segmentData.host) segmentData.host = {};
      segmentData.host[propertyName] = getUserData(propertyName, host);
    } else if (propertyName === 'signup mode' && signupMode) {
      if (!segmentData.signup) segmentData.signup = {};
      segmentData.signup[propertyName] = signupMode;
    } else if (propertyName === 'role' && role) {
      segmentData[propertyName] = role;
    } else if (propertyName === 'signup code' && signupCode) {
      segmentData[propertyName] = signupCode;
    } else if (propertyName === 'referral code' && referralCode) {
      segmentData[propertyName] = referralCode;
    } else if (propertyName === 'button' && button) {
      segmentData[propertyName] = button;
    }

  });
  console.log("Segment data", segmentData);

  return segmentData;
};


// export const signupBucketMapping = (eventProperties, props = {}) => {
//   const segmentData = {};

//   console.log("BUCKET mapping event", props);

//   eventProperties.forEach(event => {
//     const propertyName = event.Property_Name;
//     const currentUser = props.currentUser || {};
//     const currentListing = props.currentListing || {};

//     console.log("current user ddddddddddd", currentUser);

//     switch (propertyName) {
//       case 'guest name':
//       case 'guest email':
//       case 'guest flexID':
//       case 'guest address':
//       case 'guest postalcode':
//       case 'guest gueststatus':
//       case 'guest phone':
//       case 'guest miles club':
//         if (currentUser) {
//           if (!segmentData.guest) segmentData.guest = {};
//           switch (propertyName) {
//             case 'guest name':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.displayName', '');
//               break;
//             case 'guest email':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.email', '');
//               break;
//             case 'guest flexID':
//               segmentData.guest[propertyName] = get(currentUser, 'id.uuid', '');
//               break;
//             case 'guest address':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.publicData.location.selectedPlace.address', '');
//               break;
//             case 'guest postalcode':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.protectedData.postalCode', '');
//               break;
//             case 'guest gueststatus':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus', '');
//               break;
//             case 'guest phone':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.protectedData.phoneNumber', '');
//               break;
//             case 'guest miles club':
//               segmentData.guest[propertyName] = get(currentUser, 'attributes.profile.publicData.isPremiumUser', false);
//               break;
//           }
//         }
//         break;

//       case 'guest isreturning':
//         // Placeholder for future use
//         break;

//       case 'listing title':
//       case 'listing id':
//       case 'listing age':
//       case 'listing created date':
//       case 'listing approval date':
//       case 'listing status':
//       case 'listing brand name':
//       case 'listing can drive to malaysia':
//       case 'listing model name':
//       case 'listing insurance type':
//       case 'listing date of registration':
//       case 'listing kms driven':
//       case 'listing engine capacity':
//       case 'listing fuel type':
//       case 'listing is drivelah go':
//       case 'listing is instantbooking':
//       case 'listing is PHV':
//       case 'listing registrationnumber':
//       case 'listing mileage per day':
//       case 'listing delivery':
//       case 'listing category':
//       case 'listing vehicle type':
//       case 'listing successful trips':
//       case 'listing sucessful trips days':
//       case 'listing views':
//       case 'listing search results':
//       case 'listing regularprice':
//       case 'listing peakprice':
//       case 'listing weeklydiscount':
//       case 'listing monthlydiscount':
//       case 'listing rating':
//       case 'listing location':
//       case 'listing viewed source':
//       case 'listing url':
//       case 'listing seats':
//       case 'listing attractivenessscore':
//       case 'listing pricescore':
//       case 'listing longterm':
//       case 'listing availabilityscore':
//       case 'listing suburb':
//       case 'listing postal district':
//       case 'listing superhost':
//       case 'listing submitted date':
//       case 'listing live date':
//       case 'listing is new':
//       case 'listing hourly enabled':
//       case 'listing nearest stop':
//       case 'listing private insurer':
//       case 'listing bar':
//       case 'listing rms':
//       case 'listing caretaker':
//       case 'listing picture score':
//         if(currentListing) {
//           if (!segmentData.listing) segmentData.listing = {};
//           switch(propertyName) {
//             case 'listing title':
//                 segmentData.listing[propertyName] = get(currentListing, 'attributes.title');
//                 break;
//             case 'listing id':
//               segmentData.listing[propertyName] = get(currentListing, 'id.uuid');
//               break;
//             case 'listing age':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.ageOfCar');
//               break;
//             case 'listing created date':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.createdAt');
//               break;
//             case 'listing approval date':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.liveAt');
//               break;
//             case 'listing status':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.state');
//               break;
//             case 'listing brand name':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.brandName');
//               break;
//             case 'listing can drive to malaysia':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.canDriveToMalaysia');
//               break;
//             case 'listing model name':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.modelName');
//               break;
//             case 'listing insurance type':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.insurance');
//               break;
//             case 'listing date of registration':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.dateOfRegistration');
//               break;
//             case 'listing kms driven':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.drivenKm');
//               break;
//             case 'listing engine capacity':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.engineCapacity');
//               break;
//             case 'listing fuel type':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.fuelType');
//               break;
//             case 'listing is drivelah go':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.isDrivelahGoSorting');
//               break;
//             case 'listing is instantbooking':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.instantBooking');
//               break;
//             case 'listing is PHV':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.isPHV');
//               break;
//             case 'listing registrationnumber':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.license_plate_number');
//               break;
//             case 'listing mileage per day':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.millagePerDay');
//               break;
//             case 'listing delivery':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.delivery');
//               break;
//             case 'listing category':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.category');
//               break;
//             case 'listing vehicle type':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.vehicleType');
//               break;
//             case 'listing successful trips':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.numberTripDone');
//               break;
//             case 'listing sucessful trips days':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.tripDays');
//               break;
//             case 'listing views':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.views');
//               break;
//             case 'listing search results':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.searchResults');
//               break;
//             case 'listing regularprice':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.price.amount');
//               break;
//             case 'listing peakprice':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.pricing.peakPrice');
//               break;
//             case 'listing weeklydiscount':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.pricing.discount.week');
//               break;
//             case 'listing monthlydiscount':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.pricing.discount.month');
//               break;
//             case 'listing rating':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.reviews.averageRating');
//               break;
//             case 'listing location':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.location.address');
//               break;
//             case 'listing viewed source':
//               segmentData.listing[propertyName] = get(currentListing, '');
//               break;
//             case 'listing url':
//               segmentData.listing[propertyName] = get(currentListing, '');
//               break;
//             case 'listing seats':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.peopleNumber');
//               break;
//             case 'listing attractivenessscore':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.attractiveScore');
//               break;
//             case 'listing pricescore':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.priceScore');
//               break;
//             case 'listing longterm':
//               segmentData.listing[propertyName] = get(currentListing, '');
//               break;
//             case 'listing availabilityscore':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.availabilityScore');
//               break;
//             case 'listing suburb':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.location.neighbour');
//               break;
//             case 'listing postal district':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.postalDistrict');
//               break;
//             case 'listing superhost':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.isSuperHost');
//               break;
//             case 'listing submitted date':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.listingSubmittedAt');
//               break;
//             case 'listing live date':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.liveAt');
//               break;
//             case 'listing is new':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.metadata.isNewCar');
//               break;
//             case 'listing hourly enabled':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.hourlyBooking');
//               break;
//             case 'listing nearest stop':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.nearestStop');
//               break;
//             case 'listing private insurer':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.privateInsurer');
//               break;
//             case 'listing bar':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.booking_acceptance_rate');
//               break;
//             case 'listing rms':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.isRMS');
//               break;
//             case 'listing caretaker':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.isCaretaker');
//               break;
//             case 'listing picture score':
//               segmentData.listing[propertyName] = get(currentListing, 'attributes.publicData.pictureScore');
//               break;
//           }
//         }

//       case 'host name':
//       case 'host email':
//       case 'host flexID':
//       case 'host address':
//       case 'host postalcode':
//       case 'host gueststatus':
//       case 'host phone':
//       case 'host miles club':
//         if (currentUser) {
//           if (!segmentData.host) segmentData.host = {};
//           switch (propertyName) {
//             case 'host name':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.displayName', '');
//               break;
//             case 'host email':
//               segmentData.host[propertyName] = get(host, 'attributes.email', '');
//               break;
//             case 'host flexID':
//               segmentData.host[propertyName] = get(host, 'id.uuid', '');
//               break;
//             case 'host address':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.publicData.location.selectedPlace.address', '');
//               break;
//             case 'host postalcode':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.protectedData.postalCode', '');
//               break;
//             case 'host gueststatus':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.publicData.guestIdentityVerificationStatus', '');
//               break;
//             case 'host phone':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.protectedData.phoneNumber', '');
//               break;
//             case 'host miles club':
//               segmentData.host[propertyName] = get(host, 'attributes.profile.publicData.isPremiumUser', false);
//               break;
//           }
//         }
//         break;

//         case 'guest isreturning':
//           // Placeholder for future use
//           break;

//       case 'signup mode':
//         if (props.signupMode) {
//           if (!segmentData.signup) segmentData.signup = {};
//           segmentData.signup[propertyName] = props.signupMode;
//         }
//         break;

//       case 'signup role':
//         if (!segmentData.signup) segmentData.signup = {};
//         if (props.role) segmentData.signup.role = props.role;
//         break;

//       case 'signup code used':
//       case 'signup code':
//         if (!segmentData.signup) segmentData.signup = {};
//         if (props.signupCode) segmentData.signup[`${propertyName.split(' ').join('_')}`] = props.signupCode;
//         break;

//       case 'referral code':
//         if (!segmentData.signup) segmentData.signup = {};
//         if (props.referralCode) segmentData.signup.referralCode = props.referralCode;
//         break;

//       case 'ui page':
//         if (!segmentData.ui) segmentData.ui = {};
//         segmentData.ui[propertyName.split(' ')[1]] = props[propertyName.split(' ')[1]] ? props[propertyName.split(' ')[1]] : window && window.location && window.location.href ? window.location.href : undefined;
//         break;
//       case 'ui button':
//         if(props.button) {
//           if (!segmentData.ui) segmentData.ui = {};
//           segmentData.ui[propertyName.split(' ')[1]] = props[propertyName.split(' ')[1]];
//         }
//         break;

//       case 'date':
//       case 'time':
//         if (!segmentData.dateTime) segmentData.dateTime = {};
//         const now = new Date();
//         if (propertyName === 'date') {
//           segmentData.dateTime.date = now.toISOString().split('T')[0];
//         } else {
//           segmentData.dateTime.time = now.toISOString().split('T')[1];
//         }
//         break;

//       default:
//         break;
//     }
//   });
//   console.log("Segement data ui", segmentData);

//   // if (segmentData.ui) {
//   //   segmentData.ui = createUIProperties(segmentData.ui);
//   // }

//   console.log("Segment data", segmentData);

//   return segmentData;
// };


export const triggerAnalyticsEventById = async ({ event_id, eventData, props, isBackendApiCall = false, userId = null }) => {
  // Find the event by event_trigger_id
  const event = eventData.eventDetails.find(event => event.event_trigger_id === event_id);

  if (!event) {
    console.error(`Event with event_trigger_id '${event_id}' not found.`);
    return;
  }

  // Collect corresponding event properties
  const correspondingProperties = eventData.eventProperties.filter(property => event[property.Bucket] === 'x');

  // Create the GTM event properties mapping
  const segmentData = signupBucketMapping(correspondingProperties, props);

  // Prepare final event data
  const finalEventData = {
    event: event.Event_Name,
    properties: { ...segmentData }
  };

  // Log the event
  // console.log("Sending the amplitude data", finalEventData);

  if (isBackendApiCall) {
    // Call the backend API if the flag is set
    try {
      // If userId is not provided, use the userId from local storage
      if (!userId) {
        userId = getUserId();
      }
      // console.log("total event data", finalEventData.event, finalEventData.properties, userId);
      await logEventViaApi(finalEventData.event, finalEventData.properties, userId);
    } catch (error) {
      console.error('Error logging event via API:', error);
    }
  } else {
    // Log event locally
    logEvent({eventName: finalEventData.event, eventProperties: finalEventData.properties, userId});
  }
};


export const createSearchProperties = async (searchParams, result) => {
  if (!searchParams) {
    return {};
  }
  const { totalItems, totalPages } = result || {};
  const {
    dates,
    keywords,
    meta_isDrivelahGo,
    price,
    pub_brandName,
    pub_canDriveToMalaysia,
    pub_category,
    pub_fuelType,
    pub_delivery,
    pub_isPHV,
    pub_keyFeatures,
    pub_peopleNumberMax,
    pub_transmissions,
    sort,
    locationName,
    hours,
    bounds,
  } = searchParams || {};

  const [startDate, endDate] = (dates && dates.split(',')) || [];
  const [startHour, endHour] = (hours && hours.split(',')) || [];
  const usedSort =
    sort &&
    !(sort.includes('pub_instantBookingSorting') && sort.includes('meta_isDrivelahGoSorting'));
  let location = locationName;
  if (locationName && locationName.toLowerCase() !== 'current location') {
    location = locationName;
  } else {
    const response = await revertGeolocation(bounds);
    location = get(response, 'data.address', null);
  }
  return {
    location,
    pickupdate: startDate,
    pickuptime: startHour,
    dropoffdate: endDate,
    dropofftime: endHour,
    car_category_filter: !!pub_category,
    car_category_value: pub_category,
    price_filter: !!price,
    price_value: price,
    keyword_filter: !!keywords,
    keyword_value: keywords,
    dl_go_filter: !!meta_isDrivelahGo,
    brand_filter: !!pub_brandName,
    brand_value: pub_brandName,
    features_filter: !!pub_keyFeatures,
    features_value: pub_keyFeatures,
    numberofpeople_filter: !!pub_peopleNumberMax,
    numberofpeople_value: pub_peopleNumberMax,
    transmission_filter: !!pub_transmissions,
    transmission_value: pub_transmissions,
    PHV_filter: !!pub_isPHV,
    delivery_filter: !!pub_delivery,
    PHV_value: pub_isPHV,
    malaysia_filter: !!pub_canDriveToMalaysia,
    malaysia_value: pub_canDriveToMalaysia,
    fuel_filter: !!pub_fuelType,
    fuel_value: pub_fuelType,
    numberofresults: totalItems,
    numberofpagesseen: totalPages,
    used_sort: usedSort,
  };
};

export const getEventAndButtonFromTab = tab => {
  switch (tab) {
    case LOCATION:
      return [
        EVENT_LISTACAR_COMPLETED_LOCATION,
        LISTACAR_LOCATION_BUTTON_ID,
        translationEN['EditListingWizard.saveNewLocation'],
      ];
    case DESCRIPTION:
      return [
        EVENT_LISTACAR_COMPLETED_CARDETAILS,
        LISTACAR_ABOUT_BUTTON_ID,
        translationEN['EditListingWizard.saveNewDescription'],
      ];
    case FEATURES:
      return [
        EVENT_LISTACAR_COMPLETED_CARFEATURES,
        LISTACAR_FEATURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewFeatures'],
      ];
    case POLICY:
      return [
        EVENT_LISTACAR_COMPLETED_CARRULES,
        LISTACAR_CAR_RULES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPolicies'],
      ];
    case INSTRUCTIONS:
      return [
        EVENT_LISTACAR_COMPLETED_CARINSTRUCTIONS,
        LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID,
        translationEN['EditListingWizard.saveNewInstructions'],
      ];
    case PRICING:
      return [
        EVENT_LISTACAR_COMPLETED_PRICING,
        LISTACAR_PRICING_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPricing'],
      ];
    case PHOTOS:
      return [
        EVENT_LISTACAR_COMPLETED_PICTURES,
        LISTACAR_PICTURES_BUTTON_ID,
        translationEN['EditListingWizard.saveNewPhotos'],
      ];
    case INSURANCE:
      return [
        EVENT_LISTACAR_COMPLETED_INSURANCE,
        LISTACAR_INSURANCE_BUTTON_ID,
        translationEN['EditListingWizard.saveNewInsurance'],
      ];
    case AVAILABILITY:
      return [
        EVENT_LISTACAR_COMPLETED_AVAILABILITY,
        LISTACAR_AVAILABILITY_BUTTON_ID,
        translationEN['EditListingWizard.saveNewAvailability'],
      ];
    case SUBSCRIPTION:
      return [
        EVENT_LISTACAR_COMPLETED_SUBSCRIPTION,
        LISTACAR_SUBSCRIPTION_BUTTON_ID,
        translationEN['EditListingWizard.saveNewSubscription'],
      ];
    case ONBOARDING_CALL:
      return [
        EVENT_LISTACAR_COMPLETED_ONBOARDING,
        LISTACAR_ONBOARDING_CALL_BUTTON_ID,
        translationEN['EditListingWizard.saveNewOnboardingCall'],
      ];
    case NEXT_LEVEL:
      return [
        EVENT_LISTACAR_COMPLETED_NEXT_LEVEL,
        LISTACAR_NEXT_LEVEL_BUTTON_ID,
        translationEN['EditListingWizard.saveNewNextLevel'],
      ];
    default:
      return [];
  }
};

const createTripFees = transaction => {
  const { attributes = {} } = transaction;
  const { lineItems = [] } = attributes || {};
  const price = {
    totalPrice: get(attributes, 'payinTotal.amount'),
  };
  lineItems.forEach(lineItem => {
    if (lineItem.code === 'line-item/customer-commission') {
      price.tripFees = lineItem.lineTotal.amount;
    } else if (lineItem.code === 'line-item/young-customer-commission') {
      price.youngDriverFees = lineItem.lineTotal.amount;
    }
  });
  return price;
};

export const createTripProperties = transaction => {
  if (!transaction) {
    return {};
  }
  const { booking = {}, attributes: transactionAttributes = {}, listing, id = {} } = transaction;
  const { attributes = {} } = booking || {};
  const { protectedData = {} } = transactionAttributes || {};
  const tripDays = get(protectedData, 'days.regular') + get(protectedData, 'days.peak');
  const hostWillEarn = get(protectedData, 'hostWillEarn.amount');
  const tripFees = createTripFees(transaction);
  const trip_vas_excess = get(transaction, 'trip_vas_excess');
  const trip_vas_excess_2 = get(transaction, 'trip_vas_excess_2');
  const trip_vas_fuel = get(transaction, 'trip_vas_fuel');
  const trip_vas_excess_from = get(transaction, 'trip_vas_excess_from');
  const trip_vas_excess_2_from = get(transaction, 'trip_vas_excess_2_from');
  const trip_vas_fuel_from = get(transaction, 'trip_vas_fuel_from');
  const booking_unverified = get(transaction, 'attributes.protectedData.isUnverified');
  const tripProperties = {
    pickup_date:
      attributes.start && attributes.start instanceof Date
        ? attributes.start.toISOString()
        : attributes.start,
    pickup_time:
      attributes.displayStart && attributes.displayStart instanceof Date
        ? attributes.displayStart.toISOString()
        : attributes.displayStart,
    dropoff_date:
      attributes.end && attributes.end instanceof Date
        ? attributes.end.toISOString()
        : attributes.end,
    dropoff_time:
      attributes.displayEnd && attributes.displayEnd instanceof Date
        ? attributes.displayEnd.toISOString()
        : attributes.displayEnd,
    booking_trip_id: id.uuid,
    noofdays: tripDays,
    host_earnings: hostWillEarn,
    tracking_device_status: null,
    host_credits: null,
    tripfees: tripFees.tripFees,
    youngdriverfees: tripFees.youngDriverFees,
    totalprice: tripFees.totalPrice,
    trip_days: tripDays,
    trip_vas_excess: !!get(transaction, 'attributes.protectedData.isExcessReduction'),
    vasDelivery: !!get(listing, 'attributes.publicData.delivery'),
    instant_booking: !!get(listing, 'attributes.publicData.instantBooking'),
    DLGo: !!get(listing, 'attributes.metadata.isDrivelahGo'),
    trip_vas_excess_2,
    trip_vas_fuel,
    trip_vas_excess_from,
    trip_vas_excess_2_from,
    trip_vas_fuel_from,
    booking_unverified,
  };
  return tripProperties;
};
