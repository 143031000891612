import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import * as translationEN from '../../translations/en.json';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { pushGTMHostVerify } from '../../util/gtm/gtmHelpers';
import {
  EVENT_HOST_VERIFY_STRIPE_FAILURE,
  EVENT_HOST_VERIFY_STRIPE_SUCCESS,
  LISTACAR_ABOUT_BUTTON_ID,
  LISTACAR_AVAILABILITY_BUTTON_ID,
  LISTACAR_CAR_INSTRUCTIONS_BUTTON_ID,
  LISTACAR_CAR_RULES_BUTTON_ID,
  LISTACAR_FEATURES_BUTTON_ID,
  LISTACAR_INSURANCE_BUTTON_ID,
  LISTACAR_LOCATION_BUTTON_ID,
  LISTACAR_NEXT_LEVEL_BUTTON_ID,
  LISTACAR_ONBOARDING_CALL_BUTTON_ID,
  LISTACAR_PICTURES_BUTTON_ID,
  LISTACAR_PRICING_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { HelpLineNumber, NamedRedirect, Tabs } from '../../components';

import EditListingWizardTab, {
  AVAILABILITY,
  DESCRIPTION,
  FEATURES,
  INSTRUCTIONS,
  INSURANCE,
  LOCATION,
  NEXT_LEVEL,
  PAYOUTS,
  PHOTOS,
  POLICY,
  PRICING,
  PROMOTIONS,
  TRACKING_DEVICE,
  VERIFICATION,
  SUBSCRIPTION
} from './EditListingWizardTab';
import css from './EditListingWizard.css';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

// All panels can be reordered.
export const TABS = [
  LOCATION,
  DESCRIPTION,
  POLICY,
  FEATURES,
  INSTRUCTIONS,
  PRICING,
  PROMOTIONS,
  PHOTOS,
  INSURANCE,
  AVAILABILITY,
  SUBSCRIPTION,
  NEXT_LEVEL,
  VERIFICATION
];
export const VERIFIED_TABS = [
  DESCRIPTION,
  LOCATION,
  FEATURES,
  POLICY,
  INSTRUCTIONS,
  PRICING,
  PROMOTIONS,
  PHOTOS,
  INSURANCE,
  AVAILABILITY,
  SUBSCRIPTION,
  NEXT_LEVEL,
];
export const NEED_VERIFY_TABS = [
  DESCRIPTION,
  LOCATION,
  FEATURES,
  POLICY,
  INSTRUCTIONS,
  PRICING,
  PROMOTIONS,
  PHOTOS,
  INSURANCE,
  AVAILABILITY,
  SUBSCRIPTION,
  NEXT_LEVEL,
  VERIFICATION
];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;
const MAX_MOBILE_WIDTH = 768;
const TOPBAR_HEIGHT_DESKTOP = 72;
const TOPBAR_HEIGHT_TABLET = 120;

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditListingWizard.tabLabelDescription';
  } else if (tab === FEATURES) {
    key = 'EditListingWizard.tabLabelFeatures';
  } else if (tab === INSTRUCTIONS) {
    key = 'EditListingWizard.instructions';
  } else if (tab === POLICY) {
    key = 'EditListingWizard.tabLabelPolicy';
  } else if (tab === LOCATION) {
    key = 'EditListingWizard.tabLabelLocation';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === PROMOTIONS) {
    key = 'EditListingWizard.tabLabelPromotions';
  } else if (tab === AVAILABILITY) {
    key = 'EditListingWizard.tabLabelAvailability';
  } else if (tab === PHOTOS) {
    key = 'EditListingWizard.tabLabelPhotos';
  } else if (tab === INSURANCE) {
    key = 'EditListingWizard.tabLabelInsurance';
  } else if (tab === VERIFICATION) {
    key = 'EditListingWizard.tabLabelVerification';
  } else if (tab === NEXT_LEVEL) {
    key = 'EditListingWizard.tabLabelNextLevel';
  } else if (tab === TRACKING_DEVICE) {
    key = 'EditListingWizard.tabLabelTrackingDevice';
  } else if (tab === PAYOUTS) {
    key = 'EditListingWizard.payouts'
  } else if (tab === SUBSCRIPTION) {
    key = 'EditListingWizard.tabLabelSubscription';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing) => {
  const {
    availabilityPlan,
    description,
    geolocation,
    price,
    title,
    publicData,
  } = listing.attributes;
  const images = listing.images;
  const imagesLength = (images.length || 0);
  console.log('came here', tab, publicData)

  switch (tab) {
    case DESCRIPTION:
      return !!title;
    case FEATURES:
      return !!(publicData && publicData.keyFeatures);
    case POLICY:
      return !!(publicData && typeof publicData.rules !== 'undefined' && publicData.keyRules);
    case INSTRUCTIONS:
      return !!(publicData);
    case LOCATION:
      return !!(geolocation && publicData && publicData.location && publicData.location.address);
    case PRICING:
      return !!price;
    case PROMOTIONS:
      return !!price;
    case AVAILABILITY:
      return !!(availabilityPlan && geolocation && publicData && publicData.location && publicData.location.address && publicData.insurance);
    case SUBSCRIPTION:
      return !!(availabilityPlan && geolocation && publicData && publicData.location && publicData.location.address && publicData.subscriptionPlan && publicData.insurance);
    case PHOTOS:
      return true;
    case INSURANCE:
      return !!(publicData && publicData.insurance);
    case NEXT_LEVEL:
      return !!(geolocation && publicData && publicData.location && publicData.location.address && publicData.subscriptionPlan && publicData.insurance);
    case VERIFICATION:
      return !!publicData;
    case TRACKING_DEVICE:
      return true;
    case PAYOUTS:
      return !!publicData;
    default:
      return false;
  }
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};


// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;


// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

const hasFutureRequirements = (stripeAccountData, requirementType) => {
    // Check if stripeAccountData and future_requirements exist
    if (
      stripeAccountData != null &&
      stripeAccountData.future_requirements != null &&
      Array.isArray(stripeAccountData.future_requirements[requirementType])
    ) {
      // Check if the specified requirementType has requirements
      return stripeAccountData.future_requirements[requirementType].length > 0;
    }
    return false; // Return false if no future requirements found or if data is not properly structured
  };


// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      mobileToggle: false,
    };
    this.didLocationHelpLineNumber = false;
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
    this.tabsActive = this.tabsActive.bind(this);
    this.handlePositionHelpLineNumber = this.handlePositionHelpLineNumber.bind(this);
    this.mobileMenuToggle = React.createRef();
  }

  async componentDidMount() {
    document.addEventListener('scroll', this.handlePositionHelpLineNumber);
    document.addEventListener('click', this.handleOutsideClick);
    const { stripeOnboardingReturnURL } = this.props;
    if (stripeOnboardingReturnURL != null) {
      if (stripeOnboardingReturnURL === 'failure') {
        // pushGTMHostVerify({
        //   props: this.props,
        //   event: EVENT_HOST_VERIFY_STRIPE_FAILURE,
        // });

        try {
          const response = await getEventsByBucket('hostverify');
          // console.log('Events fetched successfully:', response.data);
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.HOSTVERIFY_STRIPE_FAILURE,
            eventData: response.data,
            props:  {
              ui: {
                page: 'EditListingScreen',
              }
            },
            userId: this.props.currentUser ? this.props.currentUser.id.uuid : null
          });
        } catch (error) {
          console.log('Error fetching events:', error);
        }

      }

      this.setState({ showPayoutDetails: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handlePositionHelpLineNumber);
    document.removeEventListener('click', this.handleOutsideClick);
  }

  isTabCompleted = (tab, listing, currentUser = {}) => {
    if (!listing || !tab) {
      return false
    }

    const getUserData = (user) => {
      return user &&
        user.attributes &&
        user.attributes.profile || {}
    }

    const { stripeAccount } = currentUser || {}
    const userProfile = getUserData(currentUser)
    const { protectedData: userProtectedData } = userProfile || {}
    const { id: { uuid: stripeUuid } = {} } = stripeAccount || {}
    const { residencyNumber, postalCode } = userProtectedData || {};


    const { images = [], } = this.props
    const { attributes = {} } = listing || {};
    const { publicData = {}, price = {}, title = "", description = "" } = attributes || {}
    const {
      location = {}, transmissions = [], brandName = "", modelName = "", license_plate_number = "",
      drivenKm = "", nearestStop = "", peopleNumber = "", fuelType = "",
      millagePerDay = "", rules = "", longTermRental = "", longTermPrice = {},
      isPublicHolidayPeakDays = "", pricing = {}, isThreeBookingDiscount = false,
      threeBookingDiscount = "", insurance = "", paddingHours = undefined,
      parkingInstructions = '', pickupDropoffInstructions = '', carGuide = '', hostIdentityVerificationStatus = false
    } = publicData || {}

    const isThreeBookDiscFieldAvailable = (publicData || {}).hasOwnProperty("isThreeBookingDiscount")
    const isInstantBookFieldAvailable = (publicData || {}).hasOwnProperty("instantBooking")
    const isDeliveryFieldAvailable = (publicData || {}).hasOwnProperty("delivery");

    switch (tab) {
      case LOCATION: {
        const { address, postalCode } = location || {}
        if (location && address && postalCode) {
          return true;
        }
        return false;
      }

      case DESCRIPTION: {
        if (title && description && brandName && modelName && license_plate_number && nearestStop) {
          return true;
        }
        return false;
      }

      case INSTRUCTIONS: {
        if (parkingInstructions && pickupDropoffInstructions && carGuide) {
          return true;
        }
        return false;
      }

      case FEATURES: {
        if (peopleNumber && fuelType && (transmissions && transmissions.length)) {
          return true;
        }
        return false;
      }

      case POLICY: {
        if (millagePerDay) {
          return true;
        }
        return false;
      }

      case PRICING: {
        const { amount, currency } = price || {};
        const { peakPrice } = pricing || {};
        if (amount && currency) {
          if (longTermRental &&
            !(longTermPrice && !isNaN(longTermPrice.amount) && longTermPrice.currency)) {
            return false;
          }

          if (isPublicHolidayPeakDays &&
            !(peakPrice && !isNaN(peakPrice.amount) && peakPrice.currency)) {
            return false;
          }

          return true;
        } else {
          return false;
        }
      }

      case PROMOTIONS: {
        if (isThreeBookDiscFieldAvailable) {
          if (isThreeBookingDiscount && isNaN(threeBookingDiscount)) {
            return false;
          }
          return true
        }
        return false;
      }

      case PHOTOS: {
        if (images && (images.length || 0) >= 4) {
          return true
        }
        return false;
      }

      case INSURANCE: {
        if (insurance) {
          return true
        }
        return false;
      }

      case AVAILABILITY: {
        if (!isNaN(paddingHours)) {
          return true
        }
        return false;
      }

      case SUBSCRIPTION: {
        if(publicData && publicData.subscriptionPlan) return true
      }

      case NEXT_LEVEL: {
        if((isDeliveryFieldAvailable && publicData && publicData.subscriptionPlan && insurance) || (isInstantBookFieldAvailable && publicData && publicData.subscriptionPlan && insurance) ){
          return true
        }
        return false;
      }

      case VERIFICATION: {
        if (hostIdentityVerificationStatus) {
          return true;
        }
        return false;
      }

      case PAYOUTS: {
        if (stripeUuid) {
          return true;
        }
        return false;
      }

      case TRACKING_DEVICE:
      default:
        return false;
    }
  }

  handlePositionHelpLineNumber() {
    const { viewport } = this.props;

    const isMobileLayout = viewport.width < MAX_MOBILE_WIDTH;
    const isDesktopLayout = viewport.width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const isSmallTabletLayout =
      viewport.width >= MAX_MOBILE_WIDTH && viewport.width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    if (!isMobileLayout) {
      if (
        ((window.scrollY > TOPBAR_HEIGHT_DESKTOP && isDesktopLayout) ||
          (window.scrollY > TOPBAR_HEIGHT_TABLET && isSmallTabletLayout)) &&
        !this.didLocationHelpLineNumber
      ) {
        this.didLocationHelpLineNumber = true;
        document.querySelector('#helpLineNumberBox').style.position = 'fixed';
        document.querySelector('#helpLineNumberBox').style.top = '5px';
      }
      if (window.scrollY <= TOPBAR_HEIGHT_DESKTOP && this.didLocationHelpLineNumber) {
        this.didLocationHelpLineNumber = false;
        document.querySelector('#helpLineNumberBox').style.position = '';
        document.querySelector('#helpLineNumberBox').style.top = '';
      }
    }
  }

  /**
   * Check which wizard tabs are active and which are not yet available. Tab is active if previous
   * tab is completed. In edit mode all tabs are active.
   *
   * @param isNew flag if a new listing is being created or an old one being edited
   * @param listing data to be checked
   *
   * @return object containing activity / editability of different tabs of this wizard
   */
  tabsActive(isNew, listing, tabs) {
    return tabs.reduce((acc, tab) => {
      const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
      const isActive =
        previousTabIndex >= 0 ? !isNew || tabCompleted(tabs[previousTabIndex], listing) : true;
      return { ...acc, [tab]: isActive };
    }, {});
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(id) {

    const { onPublishListingDraft, currentUser, stripeAccount } = this.props;

    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due') ||
        hasFutureRequirements(stripeAccountData, 'past_due') ||
        hasFutureRequirements(stripeAccountData, 'currently_due')
      );

    if (stripeConnected && !requirementsMissing) {
      onPublishListingDraft(id);
    } else {

      // if(payout )
      // if payout details are there and also not verified
      // if(stripeAccount) {
      //   this.setState({
      //     draftId: id,
      //     showPayoutDetails: true,
      //   });
      // }
      // else {
        onPublishListingDraft(id);
      // }
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handlePayoutSubmit(values) {
    this.props
      .onPayoutDetailsSubmit(values)
      .then(response => {
        this.props.onManageDisableScrolling('EditListingWizard.payoutModal', false);
      })
      .catch(() => {
        // do nothing
      });
  }
  // toggleMobileNavigation = () => {
  //   const { mobileToggle } = this.state;
  //
  //   this.setState({
  //     mobileToggle: !mobileToggle,
  //   });
  // };

  handleOutsideClick = e => {
    const { mobileToggle } = this.state;
    if (
      this.mobileMenuToggle &&
      this.mobileMenuToggle.current &&
      !this.mobileMenuToggle.current.contains(e.target)
    ) {
      this.setState({
        mobileToggle: false,
      });
    } else {
      this.setState({
        mobileToggle: !mobileToggle,
      });
    }
  };

  callAnalyticsEvent =  async () => {

    try {
      const response = await getEventsByBucket('hostverify');
      // console.log('Events hostverify fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.HOSTVERIFY_STRIPE_SUCCESS,
        eventData: response.data,
        props: {
          ui: {
            button: 'Save details',
            page: 'Payout Screen',
          }
        }
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  }

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      tabs,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      currentUser,
      onFetchTimeSlots,
      timeSlots,
      ...rest
    } = this.props;
    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = this.tabsActive(isNewListingFlow, currentListing, tabs);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs
        .slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: 'EditListingPage', params: { ...params, tab } };
    };

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const { returnURLType, ...pathParams } = params;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due') ||
      hasFutureRequirements(stripeAccountData, 'past_due') ||
        hasFutureRequirements(stripeAccountData, 'currently_due'));


    const returnedNormallyFromStripe = returnURLType === 'success';

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      // pushGTMHostVerify({
      //   props: this.props,
      //   event: EVENT_HOST_VERIFY_STRIPE_SUCCESS,
      // });

      this.callAnalyticsEvent();

      return (
        <NamedRedirect
          name="EditListingPage"
          params={pathParams}
          search={this.props.history ? this.props.history.location.search : ''}
        />
      );
    }
    const navClass = classNames(css.nav, {
      [css.navOpen]: this.state.mobileToggle,
    });
    return (
      <div className={classes} id={id}>
        <HelpLineNumber className={css.callUs} />
        <div className={css.mobileToggleMenuOuter}>
          <div className={css.mobileToggleMenu} ref={this.mobileMenuToggle}>
            {selectedTab && tabLabel(intl, selectedTab)}
          </div>
        </div>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={navClass}
          tabRootClassName={css.tab}
          customLinkClass={css.tabLink}
          customSelectedLinkClass={css.tabSelectedLink}
        >
          {tabs.map(tab => {
            return (
              <EditListingWizardTab
                {...rest}
                key={tab}
                showPayoutDetails={this.state.showPayoutDetails}
                timeSlots={timeSlots}
                location={this.props.history && this.props.history.location}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={tabs}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                fetchInProgress={fetchInProgress}
                currentUser={this.props.currentUser}
                initialImageUrls={
                  currentListing && currentListing.images.map(image => image.id.uuid)
                }
                onManageDisableScrolling={onManageDisableScrolling}
                stripeConnected={stripeConnected}
                isCompleted={this.isTabCompleted(tab, listing, currentUser)}
                stripeProps={{
                  getAccountLinkInProgress,
                  payoutDetailsSaveInProgress,
                  payoutDetailsSaved,
                  stripeAccountData,
                  createStripeAccountError,
                  updateStripeAccountError,
                  fetchStripeAccountError,
                  stripeAccountFetched,
                  onPayoutDetailsFormChange,
                  onGetStripeConnectAccountLink,
                  onPayoutDetailsSubmit: rest.onPayoutDetailsSubmit,
                  stripeAccount,
                  stripeConnected,
                  sParams: params,
                  requirementsMissing
                }}
              />
            );
          })}
        </Tabs>
        {/* <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          disableCloseBtn={true}
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingPhotosPanel.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingPhotosPanel.payoutModalTitlePayoutPreferences" />
            </h1>
            <p className={css.modalMessage}>
              <FormattedMessage id="EditListingPhotosPanel.payoutModalInfo" />
            </p>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : (
              <StripeConnectAccountForm
                disabled={formDisabled}
                inProgress={payoutDetailsSaveInProgress}
                ready={payoutDetailsSaved}
                stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                savedCountry={savedCountry}
                submitButtonText={intl.formatMessage({
                  id: 'StripePayoutPage.submitButtonText',
                })}
                stripeAccountError={
                  createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
                }
                stripeAccountFetched={stripeAccountFetched}
                onChange={onPayoutDetailsFormChange}
                onSubmit={rest.onPayoutDetailsSubmit}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                stripeConnected={stripeConnected}
              >
                {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
                  <StripeConnectAccountStatusBox
                    type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                    inProgress={getAccountLinkInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_verification'
                    )}
                  />
                ) : stripeConnected && savedCountry ? (
                  <StripeConnectAccountStatusBox
                    type="verificationSuccess"
                    inProgress={getAccountLinkInProgress}
                    disabled={payoutDetailsSaveInProgress}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                      'custom_account_update'
                    )}
                  />
                ) : null}
              </StripeConnectAccountForm>
            )}
          </div>
        </Modal> */}
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  tabs: array.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS).isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  location: object,
  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
    createStripeAccountError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
