import React from 'react';
import { bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import {
  getUserTxRole, isNewAdminDropOffTransition,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  txGetPromoLineItem,
  txHasBeenDelivered,
  txIsAccepted,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsDeclined,
  txIsDispute,
  txIsDropoffPending,
  txIsEnquired,
  txIsNonRefunded,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPickedUp,
  txIsPickupPending,
  txIsRefunded,
  txIsRequested,
  txIsShu,
  txIsUnverifiedInstantRequested,
  txIsUnverifiedRequested,
  txIsUnverifiedSubmitted,
  txIsUnverifiedSubmittedInstant,
  txIsWaitingForRefund,
  txIsWithdrawn,
} from '../../util/transaction';
import { formatMoney } from '../../util/currency';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { ensureCurrentUser, userDisplayName } from '../../util/data';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  Footer,
  IconSpinner,
  InboxNav,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  UserDisplayName,
} from '../../components';
import { LocationSearchForm } from '../../forms';
import { NotFoundPage, TopbarContainer } from '../../containers';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import { loadData, messageRead } from './InboxPage.duck';
import css from './InboxPage.css';
import {
  computedGuestDashboardLabel,
  computedHostDashboardLabel,
} from '../../components/TripPanel/transitionHelper';
import { get } from 'lodash';

const { Money } = sdkTypes;

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type, isProvider) => {
  const isOrder = type === 'order';
  const lastTransition = tx && tx.attributes.lastTransition;

  const timeZone = get(tx, 'listing.attributes.publicData.listingTimezone', 'Australia/Sydney')

  const displayStart = get(tx, 'booking.attributes.displayStart');
  const displayEndDateForUser = moment(displayStart).tz(timeZone);
  const dateShowDropOff = displayEndDateForUser.clone();

  const currentTime = moment().tz(timeZone);

  let timeDifferenceInMinutes = dateShowDropOff.diff(currentTime, 'minutes');

  const isWithinFifteenMinutes =  timeDifferenceInMinutes <= 15;

  if (txIsEnquired(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateRequested',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };

    return requested;
  } else if (txIsUnverifiedRequested(tx) || txIsUnverifiedInstantRequested(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.verificationPending',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };
    return requested;
  } else if (txIsUnverifiedSubmitted(tx) || txIsUnverifiedSubmittedInstant(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.verificationSubmitted',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };
    return requested;
  } else if (!isProvider && txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsAwaitUpdateBookingBeforeDropOff(tx)) {
    return {
      nameClassName: !isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: !isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingUpdateBooking',
      }),
    };
  } else if (txIsAwaitUpdateBookingBeforePickUp(tx)) {
    return {
      nameClassName: !isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: !isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingUpdateBooking',
      }),
    };
  } else if (
    lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsWithdrawn(tx)) {
    return {
      nameClassName: css.nameDeclined,
      bookingClassName: css.bookingDeclined,
      lastTransitionedAtClassName: css.lastTransitionedAtDeclined,
      stateClassName: css.stateDeclined,
      state: intl.formatMessage({
        id: 'InboxPage.stateWithdraw',
      }),
    };
  } else if (txIsBeingPickedUp(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateBeingPickedUp',
      }),
    };
  } else if (txIsPickedUp(tx) || txIsShu(tx)) {
    return {
      nameClassName: css.nameAccepted,
      bookingClassName: css.bookingAccepted,
      lastTransitionedAtClassName: css.lastTransitionedAtAccepted,
      stateClassName: css.stateAccepted,
      state: intl.formatMessage({
        id: 'InboxPage.statePickedup',
      }),
    };
  } else if (txIsBeingDropOff(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateBeingDropoff',
      }),
    };
  } else if (txIsDropoffPending(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateDropoffPending',
      }),
    };
  } else if (txIsPickupPending(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.statePickupPending',
      }),
    };
  } else if (txIsDispute(tx)) {
    return {
      nameClassName: css.nameRequested,
      bookingClassName: css.bookingRequested,
      lastTransitionedAtClassName: css.lastTransitionedAtRequested,
      stateClassName: css.stateRequested,
      state: intl.formatMessage({
        id: 'InboxPage.stateDispute',
      }),
    };
  } else if (txIsAccepted(tx) && isWithinFifteenMinutes) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateBeingPickedUp',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCanceled(tx) || txIsNonRefunded(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (!isProvider && txIsWaitingForRefund(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.waitingForRefund',
      }),
    };
  } else if (isProvider && (txIsWaitingForRefund(tx) || txIsRefunded(tx))) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsRefunded(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.refunded',
      }),
    };
  } else if (txHasBeenDelivered(tx) && isNewAdminDropOffTransition(tx.attributes.lastTransition)) {
    const stateClassName = isProvider ? css.stateActionNeeded : css.stateSucces;

    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: stateClassName,
      state: intl.formatMessage({
        id: 'InboxPage.stateTripReview',
      }),
    };
  } else if (txHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

const bookingData = (tx, isOrder, intl) => {
  if (isOrder) {
    // for guest
    const bookingPrice = tx.attributes.payinTotal;
    const price =
      bookingPrice === null
        ? formatMoney(intl, new Money(0, 'SGD'))
        : formatMoney(intl, bookingPrice);
    return { price };
  }

  const promoLineItem = txGetPromoLineItem(tx);

  const promoAmount = promoLineItem ? promoLineItem.lineTotal.amount : 0;

  const totalPrice = tx.attributes.payoutTotal || { amount: 0 };

  const finalTotalPrice = new Money(totalPrice.amount - (promoAmount || 0), 'SGD');

  return { price: formatMoney(intl, finalTotalPrice) };
};

const isDateToday = time => {
  const now = new Date();
  return (
    now.getDate() === time.getDate() &&
    now.getMonth() === time.getMonth() &&
    now.getFullYear() === time.getFullYear()
  );
};

const isDateInThisWeek = time => {
  const now = new Date();
  const diff = now.getTime() - time.getTime();
  const timeConst = 1000 * 60 * 60 * 24;
  const remainNow =
    (now.getHours() * 3600 * 1000 +
      now.getMinutes() * 60 * 1000 +
      now.getSeconds() * 1000 +
      now.getMilliseconds()) /
    timeConst;
  return now.getDay() + remainNow + diff / timeConst < 7;
};

const isDateInThisYear = time => {
  const now = new Date();
  return time.getFullYear() === now.getFullYear();
};

const getMonthShort = time => {
  return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(time);
};

const getWeekDateShort = time => {
  return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(time);
};

const getTimeHour12 = time => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
    .format(time)
    .toLowerCase();
};

const convetTXTime = tx => {
  const date = new Date(tx.attributes.lastTransitionedAt);
  const now = new Date();
  let displayTime = '';
  if (isDateToday(date)) {
    displayTime = 'Today';
  } else if (isDateInThisWeek(date)) {
    displayTime = getWeekDateShort(date);
  } else if (isDateInThisYear(date)) {
    displayTime = `${getMonthShort(date)} ${date.getDate()}`;
  } else {
    displayTime = `${getMonthShort(date)} ${date.getDate()} ${date.getFullYear()}`;
  }
  displayTime = `${displayTime}, ${getTimeHour12(date)}`;
  return displayTime;
};

const getBookingDateRange = tx => {
  const startDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }).format(tx.booking.attributes.displayStart);
  const endDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }).format(tx.booking.attributes.displayEnd);
  return `${startDate} - ${endDate}`;
};

// Functional component as internal helper to print BookingInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType, type } = props;
  const isEnquiry = txIsEnquired(tx);

  const { customer, provider } = tx;

  const otherUser = isOrder ? provider : customer;
  const bannedUserDisplayName = intl.formatMessage({
    id: 'InboxPage.bannedUserDisplayName',
  });
  const otherUserDisplayName = userDisplayName(otherUser, bannedUserDisplayName);

  if (isEnquiry) {
    return null;
  }

  return (
    <div className={classNames(css.bookingInfo, bookingClassName)}>
      {convetTXTime(tx)}
      <span className={css.itemPrice}>
        {!isOrder && !!tx.listing && tx.listing.attributes.title}
        {isOrder && otherUserDisplayName} ({getBookingDateRange(tx)})
      </span>
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  // tx: propTypes.transaction,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const InboxItem = props => {
  const { unitType, type, tx, intl, stateData, ownRole, readArr } = props;
  const { customer, provider } = tx;
  const isOrder = type === 'order';
  const dispatch = useDispatch();
  const transactionTimezone =
    tx &&
    tx.attributes &&
    tx.attributes.protectedData &&
    tx.attributes.protectedData.transactionTimezone;
  const timeZone = getDefaultTimeZoneOnBrowser(transactionTimezone) || 'Asia/Singapore';
  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  // console.log('props', isOrder, txIsEnquired(tx), readArr);
  let unRead = true;
  let readData = readArr;

  if (readData && readData.includes(tx.id.uuid)) {
    unRead = false;
  }
  const isSaleNotification = !isOrder && (txIsRequested(tx) || (txIsEnquired(tx) && unRead));
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const { price } = bookingData(tx, isOrder, intl);
  const lastTx = tx && tx.attributes && tx.attributes.lastTransition;
  let displaystarttime =
    tx && tx.booking && tx.booking.attributes && tx.booking.attributes.displayStart;
  let displayEndtime =
    tx && tx.booking && tx.booking.attributes && tx.booking.attributes.displayEnd;
  const startTimeDiff = moment(displaystarttime)
    .tz(timeZone)
    .diff(moment(), 'minutes');

  const endTimeDiff = moment(displayEndtime)
    .tz(timeZone)
    .diff(moment(), 'minutes');

  const homeDeliveryAddon =
    (tx &&
      tx.attributes &&
      tx.attributes.protectedData &&
      tx.attributes.protectedData.isDelivery) ||
    (tx && tx.attributes && tx.attributes.metadata && tx.attributes.metadata.isDelivery);
  let labelResponse =
    ownRole == 'customer'
      ? computedGuestDashboardLabel(lastTx, startTimeDiff, endTimeDiff, homeDeliveryAddon)
      : computedHostDashboardLabel(lastTx);

  const _onRead = () => {
    let readMessage = readArr;
    // console.log('on read');
    if (txIsEnquired(tx) && !readMessage.includes(tx.id.uuid)) {
      let data = tx.id.uuid;
      dispatch(messageRead(data));
    }
  };

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={otherUser} />
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
        onClick={() => _onRead()}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div
            className={classNames(
              css.itemUsername,
              txIsEnquired(tx)
                ? !unRead
                  ? css.nameNotEmphasized
                  : css.nameEmphasized
                : stateData.nameClassName
            )}
          >
            {isOrder ? (!!tx.listing ? tx.listing.attributes.title : null) : otherUserDisplayName}
          </div>
          <BookingInfoMaybe
            bookingClassName={stateData.bookingClassName}
            intl={intl}
            isOrder={isOrder}
            tx={tx}
            unitType={unitType}
            type={type}
          />
        </div>
        <div className={css.itemState}>
          <div
            style={{ backgroundColor: labelResponse.bgColor, borderRadius: 10 }}
            className={classNames(css.stateName, stateData.stateClassName)}
          >
            {/* {stateData.state} */}
            {/* {computedDashboardLabel(lastTx)} */}
            <h5 style={{ color: labelResponse.color }}> {labelResponse.text}</h5>
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            // title={lastTransitionedAt.long}
          >
            {labelResponse.text == 'Enquiry' ? '' : price}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  // tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    currentUserHasListings,
    fetchInProgress,
    fetchOrdersOrSalesError,
    readArr,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    history,
  } = props;
  // console.log(transactions);
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const toTxItem = tx => {
    const ownRole = currentUser && getUserTxRole(currentUser.id, tx);
    const isProvider = ownRole === 'provider';

    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type, isProvider);

    if (config.bookingProcessAliasDrivelahGoDeposit.includes(tx.attributes.processName)) {
      return null;
    }

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          readArr={readArr}
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          ownRole={ownRole}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const handleSearchSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResultsWrapper}>
        <div className={css.noResultsTitle}>
          <FormattedMessage
            id={
              isOrders
                ? 'InboxPage.noOrdersFound'
                : currentUserHasListings
                ? 'InboxPage.noSalesFound'
                : 'InboxPage.noSalesnoListingFound'
            }
          />
        </div>
        <p className={css.noResultsDescription}>
          <FormattedMessage
            id={
              isOrders
                ? 'InboxPage.noOrdersFoundDesc'
                : currentUserHasListings
                ? 'InboxPage.noSalesFoundDesc'
                : 'InboxPage.noSalesnoListingFoundDesc'
            }
            values={{ newline: <br /> }}
          />
        </p>
        {!isOrders ? (
          <NamedLink
            className={css.listYourCarLink}
            name={currentUserHasListings ? 'ManageListingsPage' : 'NewListingPage'}
          >
            <FormattedMessage
              id={currentUserHasListings ? 'InboxPage.manageYourListing' : 'InboxPage.listYourCar'}
            />
          </NamedLink>
        ) : (
          <LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />
        )}
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {providerNotificationBadge}
        </span>
      ),
      selected: !isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
    },
  ];
  const nav = (
    <TabNav
      selectedClass={css.customSelectedTabLink}
      linkClass={css.customTabLink}
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabs}
    />
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} className={css.page}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
            contactUsClassName={css.contactUs}
          />
          <InboxNav selectedPageName="InboxBasePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={`${css.navigation} ${css.mainBodySideNav}`}>
          <h1 className={css.title}>
            <FormattedMessage id="InboxPage.title" />
          </h1>
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.mainBody}>
          {error}
          <ul className={css.itemList}>
            {!fetchInProgress ? (
              transactions.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {pagingLinks}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  // transactions: arrayOf(propTypes.transaction),

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    readArr,
  } = state.InboxPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserHasListings,
  } = state.user;
  return {
    currentUser,
    currentUserHasListings,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    readArr,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs).filter(
      tx => !config.bookingProcessAliasAdmin.includes(tx.attributes.processName)
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  onMessageRead: read => dispatch(messageRead(read)),
});

const InboxPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
