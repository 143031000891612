import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  restoreTransaction,
} from '../../util/data';
import { calculateBookingDays, dateFromAPIToLocalNoon } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { TRANSITION_FUEL_CHARGING_REQUEST, txIsPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { handleCardPayment, initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { estimateBreakdown, resetCode } from '../ListingPage/ListingPage.duck';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
  TripPanel,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_REQUEST_DECLINED_GUEST,
  EVENT_BOOK_REQUEST_DECLINED_HOST,
  PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
  PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromTransaction } from '../../util/gtm/gtmHelpers';
import {
  $bookingTx,
  $isCancelUpdateBookingsError,
  $isCancelUpdateBookingsPending,
  acceptSale,
  addFuelInclusionAndSaveCard,
  confirmPayment,
  declineSale,
  estimateFuelBreakdown,
  fetchCancelUpdateBooking,
  fetchFuelChildTransaction,
  fetchMoreMessages,
  fetchTimeSlots,
  fetchTransaction,
  fuelNotificationFor500,
  fuelNotificationWithoutCharge,
  getDlGoTripDistance,
  handlePaymentFuelAfterFail,
  initiateOrder,
  loadData,
  requestToUpdateBooking,
  sendMessage,
  sendReview,
  setInitialValues,
  transit,
  updateListingOdometerData,
  uploadInteriorPhotoToMetadata,
  getDistanceFromPickUp
} from './TransactionPage.duck';
import css from './TransactionPage.css';
import config from '../../config';
import { STRIPE_PI_USER_ACTIONS_DONE_STATUSES, USE_SAVED_CARD } from '../AddOnsPage/AddOnsPage';
import { stripeCustomer } from '../CheckoutPage/CheckoutPage.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import get from 'lodash/get';
import { flatten } from 'lodash';
import { $fuelPriceByListing } from '../../ducks/FuelPrice.duck';
import { $updateBookingTxsByParentTxId } from '../EditTripPage/EditTripPage.duck';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents.js';
import { $isLimitedUser } from '../../ducks/Auth.duck';
import {
  $isCancelUpdateBookingByAdminPending,
  $isAcceptUpdateBookingByAdminPending,
} from '../../modules/admin-actions/update-booking/adminUpdateBooking.selectors';
import {
  cancelUpdateBookingByAdmin,
  acceptUpdateBookingByAdmin,
} from '../../modules/admin-actions/update-booking/adminUpdateBooking.duck';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;
// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    isCancelUpdateBookingsPending,
    isCancelUpdateBookingsError,
    isCancelUpdateBookingByAdminPending,
    isAcceptUpdateBookingByAdminPending,
    isLimitedUser,
    updateBookingTxs,
    dlGoTripDistanceInProgress,
    fuelPriceByListing,
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    customerUpdateBookingError,
    declineInProgress,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    timeSlots,
    fetchTimeSlotsError,
    useInitialValues,
    transitInProgress,
    transitError,
    onTransit,
    onFetchTransaction,
    ownListing,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    location,
    onResetCode,
    onHandlePaymentFuel,
    onAddFuelInclusionAndSaveCard,
    viewport,
    onUploadInteriorPhotoToMetadata,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    monthlyTimeSlots,
    onEstimateBreakdown,
    onRequestToUpdateBooking,
    onFetchCancelUpdateBooking,
    estimatedTx,
    estimateError,
    estimateBreakdownInProgress,
    onFetchTimeSlots,
    customerUpdateBookingInProgress,
    transactionUpdateBooking,
    listingForMap,
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    stripeCustomerFetched,
    initiateOrderError,
    onInitiateOrder,
    fetchStripeCustomer,
    onHandleCardPayment,
    onConfirmPayment,
    onSavePaymentMethod,
    onEstimateFuelBreakdown,
    initiateOrderInProgress,
    fetchFuelChildTransaction,
    fuelTransaction,
    handleCardPaymentInProgress,
    confirmPaymentInProgress,
    savePaymentMethodInProgress,
    fuelNotificationWithoutCharge,
    getDlGoDistance,
    onUpdateListingOdometerData,
    onFuelNotificationFor500,
    onCancelUpdateBookingByAdmin,
    onAcceptUpdateBookingByAdmin,
    getDistanceFromPickUp,
    distanceFromPickUp,
    userLocation
  } = props;
  const [dropOffMarker, setDropOffMarker] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [isCloseFuelWindow, setCloseFuelWindow] = useState(false);
  const [odometerEndValue, setOdometerEndValue] = useState(0);
  const currentTransaction = restoreTransaction(ensureTransaction(transaction));
  const transactionWithoutRestore = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;
  const fuelPrice = fuelPriceByListing(currentListing);
  const currentOdometerReading = get(currentListing, 'attributes.publicData.currentOdometerReading', null);

  useEffect(() => {
    fetchStripeCustomer();
  }, []);

  useEffect(() => {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(stripe);

    }
  }, [])

  useEffect(() => {
    const id = get(transaction, 'attributes.metadata.fuelChildTransactionId', null);
    if (transaction && !dataLoaded && id) {
      fetchFuelChildTransaction(id);
    }
  }, [transaction, dataLoaded]);

  useEffect(() => {
    if (transaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [transaction, dataLoaded]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(transactionWithoutRestore) &&
    isCustomerRole &&
    transactionWithoutRestore.attributes.lineItems
  ) {
    const currentBooking = ensureListing(transactionWithoutRestore.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: transactionWithoutRestore,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing: currentListing,
      // enquired transaction should be passed to CheckoutPage
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  };

  const onStripeInitialized = stripe => {
    setStripe(stripe);
  };

  const handlePaymentIntent = handlePaymentParams => {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onConfirmPaymentDeposit,
    } = props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const fuelSpeculativeTx = ensureTransaction(speculatedTransaction);
    const parentTx = pageData.parentTransaction;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const { bookingProcess, isMinimumPrice, listing, ...fnRestParams } = fnParams;
      if (fuelPrice) {
        fnRestParams.fuelPrice = fuelPrice;
      }
      const isDrivelahGo =
        parentTx.attributes.protectedData.isDrivelahGo ||
        currentListing.attributes.metadata.isDrivelahGo
      ;
      const photoObjects =  isDrivelahGo && parentTx &&
      parentTx.attributes &&
      parentTx.attributes.metadata &&
      parentTx.attributes.metadata.photoObjects ?
        parentTx.attributes.metadata.photoObjects :
        parentTx.attributes.protectedData.photoObjects;
      const photoObjectsFlat = photoObjects ? flatten(photoObjects) : [];
      const odometerPhotosObject = photoObjectsFlat ? photoObjectsFlat.find((photo) => photo.odometerPhotos) : [];
      const odometerStartValue = odometerPhotosObject && parseInt(odometerPhotosObject.odometerStartData);
      const dlGoTripDistance = get(fuelSpeculativeTx, 'attributes.protectedData.dlGoTripDistance', null);

      // If paymentIntent exists, order has been initiated previously.
      return onInitiateOrder(
        fnRestParams,
        odometerStartValue,
        odometerEndValue,
        dlGoTripDistance,
        parentTx.id,
        bookingProcess,
        isMinimumPrice,
        listing,
        selectedPaymentFlow,
        handlePaymentParams,
      );
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity
      const order = ensureTransaction(fnParams);
      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
            payment_method_data: {
              billing_details: billingDetails,
            },
          }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        fuelInitiatedTx: order,
        userId: props.currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent, order })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPaymentDeposit = ({ deposit, transactionId, order, mainTx }) => {
      if (deposit) {
        return onConfirmPaymentDeposit({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing } = pageData;
      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        parentTransactionId: transaction.id.uuid,
        userId: currentUser.id.uuid,
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSavePaymentMethod
    );

    let bookingProcess = config.additionalTransactionProcess;
    let transition = TRANSITION_FUEL_CHARGING_REQUEST;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const { hasMastercardPromoBeenUsed } = currentUser.attributes.profile.metadata;
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};


    const orderParams = {
      transition,
      listingId: currentListing.id,
      bookingStart: parentTx.booking.attributes.start,
      bookingEnd: parentTx.booking.attributes.end,
      bookingDisplayStart: parentTx.booking.attributes.displayStart,
      bookingDisplayEnd: parentTx.booking.attributes.displayEnd,
      protectedData: parentTx.attributes.protectedData,
      preauthenListingId: config.preauthenListingId,
      quantity: calculateBookingDays(
        parentTx.booking.attributes.displayStart,
        parentTx.booking.attributes.displayEnd
      ),
      bookingProcess,
      listing: currentListing,
      userId: currentUser.id.uuid,
      parentTransactionId: transaction.id.uuid,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  };

  const payForFuelFromModal = values => {
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: {
        bookingData: {},
        bookingDates: {},
        listing: currentListing,
        parentTransaction: transaction,
        timeSlotsObj: {},
      },
      speculatedTransaction: estimatedTx,
      stripe: stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      paymentDepositIntent,
    };

    handlePaymentIntent(requestPaymentParams)
      .then(() => {
        setDropOffMarker(true);
      })
    ;
    setCloseFuelWindow(true);
  };

  const handleFuelSubmit = (fuelEstimatedTx) => {
    const currUser = ensureCurrentUser(currentUser);
    const profile = currUser.attributes.profile;
    const profileProtectedData = profile.protectedData;
    const newBillingDetails = {
      name: `${profile.firstName} ${profile.lastName}`,
      email: currUser.attributes.email,
      address: {
        city: profileProtectedData.city,
        country: profileProtectedData.country,
        state: profileProtectedData.state,
        postal_code: profileProtectedData.postalCode,
        line1: `${profileProtectedData.blockNo} ${profileProtectedData.location.search}`,
        line2: `${profileProtectedData.floorUnit}`,
      },
    };
    const newRequestPaymentParams = {
      pageData: {
        bookingData: {},
        bookingDates: {},
        listing: currentListing,
        parentTransaction: transaction,
        timeSlotsObj: {},
      },
      speculatedTransaction: fuelEstimatedTx,
      stripe: stripe,
      card: null,
      billingDetails: newBillingDetails,
      message: undefined,
      paymentIntent,
      selectedPaymentMethod: 'defaultCard',
      saveAfterOnetimePayment: false,
      paymentDepositIntent,
    };
    return handlePaymentIntent(newRequestPaymentParams);
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const acceptSaleGTM = async () => {
    // initiateEventFromTransaction({
    //   props,
    //   transaction,
    //   buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
    //   buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
    //   event: EVENT_BOOK_REQUEST_ACCEPTED_HOST,
    //   isMultipleButton: true,
    //   existTransaction: true,
    // });
    try {
      const response = await getEventsByBucket('book');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: EVENT_BOOK_REQUEST_ACCEPTED_HOST,
        eventData: response.data,
        isBackendApiCall: true,
        props: {
            ...props,
            buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
            buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
            event: EVENT_BOOK_REQUEST_ACCEPTED_HOST,
            isMultipleButton: true,
            existTransaction: true,
        }
      });
      triggerAnalyticsEvent({
        event_id: EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
        eventData: response.data,
        isBackendApiCall: true,
        props: {
          ...props,
          buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
          buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
          event: EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
          isMultipleButton: true,
          existTransaction: true,
          userDiffActionTaker: true,
        }
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }

    // initiateEventFromTransaction({
    //   props,
    //   transaction,
    //   buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
    //   buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
    //   event: EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
    //   isMultipleButton: true,
    //   existTransaction: true,
    //   userDiffActionTaker: true,
    // });
  };

  const declineSaleGTM = async () => {
    // initiateEventFromTransaction({
    //   props,
    //   transaction,
    //   buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
    //   buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
    //   event: EVENT_BOOK_REQUEST_DECLINED_HOST,
    //   isMultipleButton: true,
    //   existTransaction: true,
    // });
    try {
      const response = await getEventsByBucket('book');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: EVENT_BOOK_REQUEST_DECLINED_HOST,
        eventData: response.data,
        isBackendApiCall: true,
        props: {
          ...props,
            buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
            buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
          event: EVENT_BOOK_REQUEST_DECLINED_HOST,
          isMultipleButton: true,
          existTransaction: true,
        }
      });
      triggerAnalyticsEvent({
        event_id: EVENT_BOOK_REQUEST_DECLINED_GUEST,
        eventData: response.data,
        isBackendApiCall: true,
        props: {
          ...props,
          buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
          buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
          event: EVENT_BOOK_REQUEST_DECLINED_GUEST,
          isMultipleButton: true,
          existTransaction: true,
          userDiffActionTaker: true,
        }
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
    // initiateEventFromTransaction({
    //   props,
    //   transaction,
    //   buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
    //   buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
    //   event: EVENT_BOOK_REQUEST_DECLINED_GUEST,
    //   isMultipleButton: true,
    //   existTransaction: true,
    //   userDiffActionTaker: true,
    // });
  };

  // If paymentIntent status is not waiting user action,
  // handleCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
    (paymentDepositIntent &&
      STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    // isCustomerRole ? (
    <TripPanel
      currentOdometerReading={currentOdometerReading}
      isCancelUpdateBookingsPending={isCancelUpdateBookingsPending}
      isCancelUpdateBookingsError={isCancelUpdateBookingsError}
      isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
      isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
      isLimitedUser={isLimitedUser}
      updateBookingTxs={updateBookingTxs}
      dlGoTripDistanceInProgress={dlGoTripDistanceInProgress}
      fuelPrice={fuelPrice}
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      transactionUpdateBooking={transactionUpdateBooking}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onDeclineSale={onDeclineSale}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptSaleError={acceptSaleError}
      customerUpdateBookingError={customerUpdateBookingError}
      declineSaleError={declineSaleError}
      acceptSaleGTM={acceptSaleGTM}
      declineSaleGTM={declineSaleGTM}
      nextTransitions={processTransitions}
      onSubmitBookingRequest={handleSubmitBookingRequest}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      transitInProgress={transitInProgress}
      transitError={transitError}
      onTransit={onTransit}
      onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
      ownListing={ownListing}
      transactionWithoutRestore={transactionWithoutRestore}
      acceptButtonName={PROVIDER_ACCEPT_BOOKING_BUTTON_NAME}
      declineButtonName={PROVIDER_DECLINE_BOOKING_BUTTON_NAME}
      location={location}
      history={history}
      viewport={viewport}
      callSetInitialValues={callSetInitialValues}
      onInitializeCardPaymentData={onInitializeCardPaymentData}
      onResetCode={onResetCode}
      isMobileLayout={isMobileLayout}
      isTabletLayout={isTabletLayout}
      uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
      uploadInteriorSuccess={uploadInteriorSuccess}
      monthlyTimeSlots={monthlyTimeSlots}
      onHandlePaymentFuel={onHandlePaymentFuel}
      onAddFuelInclusionAndSaveCard={onAddFuelInclusionAndSaveCard}
      onEstimateBreakdown={onEstimateBreakdown}
      onRequestToUpdateBooking={onRequestToUpdateBooking}
      estimatedTx={estimatedTx}
      estimateError={estimateError}
      estimateBreakdownInProgress={estimateBreakdownInProgress}
      onFetchTimeSlots={onFetchTimeSlots}
      customerUpdateBookingInProgress={customerUpdateBookingInProgress}
      onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
      listingForMap={listingForMap}
      onFetchTransaction={onFetchTransaction}
      handleFuelSubmit={handleFuelSubmit}
      stripeCustomerFetched={stripeCustomerFetched}
      hasPaymentIntentUserActionsDone={hasPaymentIntentUserActionsDone}
      onStripeInitialized={onStripeInitialized}
      onEstimateFuelBreakdown={onEstimateFuelBreakdown}
      setOdometerEndValue={setOdometerEndValue}
      isCloseFuelWindow={isCloseFuelWindow}
      initiateOrderInProgress={initiateOrderInProgress}
      fuelTransaction={fuelTransaction}
      handleCardPaymentInProgress={handleCardPaymentInProgress}
      confirmPaymentInProgress={confirmPaymentInProgress}
      savePaymentMethodInProgress={savePaymentMethodInProgress}
      payForFuelFromModal={payForFuelFromModal}
      dropOffMarker={dropOffMarker}
      setDropOffMarker={setDropOffMarker}
      fuelNotificationWithoutCharge={fuelNotificationWithoutCharge}
      getDlGoDistance={getDlGoDistance}
      onUpdateListingOdometerData={onUpdateListingOdometerData}
      onFuelNotificationFor500={onFuelNotificationFor500}
      onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
      onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
      getDistanceFromPickUp = {getDistanceFromPickUp}
      distanceFromPickUp = {distanceFromPickUp}
      userLocation = {userLocation}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  monthlyTimeSlots: null,
  fetchTimeSlotsError: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number, object } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  monthlyTimeSlots: object,
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,
  onUploadInteriorPhotoToMetadata: func.isRequired,
  onRequestToUpdateBooking: func.isRequired,
  onFetchCancelUpdateBooking: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  initiateOrderInProgress: bool.isRequired,
  fuelTransaction: propTypes.transaction,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    customerUpdateBookingError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    monthlyTimeSlots,
    fetchTimeSlotsError,
    transitInProgress,
    transitError,
    processTransitions,
    nextLongTermTransaction,
    currentChildLongTermTransaction,
    childLongTermTransactions,
    customerUpdateBookingInProgress,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    transactionUpdateBookingRef,
    estimatedTx,
    listingForMap,
    initiateOrderInProgress,
    dlGoTripDistanceInProgress,
    distanceFromPickUp,
    userLocation
  } = state.TransactionPage;

  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;

  const {
    stripeCustomerFetched,
    initiateOrderError,
  } = state.CheckoutPage;

  const transactionsUpdateBooking = getMarketplaceEntities(state, transactionUpdateBookingRef ? [transactionUpdateBookingRef] : []);
  const transactionUpdateBooking = transactionsUpdateBooking.length > 0 ? transactionsUpdateBooking[0] : null;

  if (nextLongTermTransaction && transactionsUpdateBooking) {
    transactionsUpdateBooking.nextTransaction = nextLongTermTransaction;
    transactionsUpdateBooking.childTransaction = childLongTermTransactions;
    transactionsUpdateBooking.currentChildTransaction = currentChildLongTermTransaction;
  }

  const { timeSlots, estimateError } = state.ListingPage;
  const { currentUser } = state.user;

  const transaction = $bookingTx(state);

  let ownListing = null;
  if (transaction) {
    const ownListingRef = {
      id: transaction.listing.id,
      type: 'ownListing',
    };
    const ownListings = getMarketplaceEntities(state, [ownListingRef]);
    ownListing = ownListings.length > 0 ? ownListings[0] : null;
  }

  const fuelTransactionId = get(transaction, 'attributes.metadata.fuelChildTransactionId', null);
  const fuelTransactionEntities = transaction && fuelTransactionId
    ? getMarketplaceEntities(state, [{ id: { uuid: fuelTransactionId }, type: 'transaction' }])
    : null
  ;
  const fuelTransaction = (fuelTransactionEntities && fuelTransactionEntities.length) ? fuelTransactionEntities[0] : null;
  const bookingTxId = transaction && transaction.id.uuid;

  return {
    currentUser,
    fetchTransactionError,
    acceptSaleError,
    customerUpdateBookingError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    monthlyTimeSlots,
    fetchTimeSlotsError,
    transitInProgress,
    transitError,
    ownListing,
    processTransitions,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    estimatedTx,
    estimateError,
    estimateBreakdownInProgress: state.TransactionPage.estimateBreakdownInProgress,
    confirmPaymentInProgress: state.TransactionPage.confirmPaymentInProgress,
    handleCardPaymentInProgress: state.stripe.handleCardPaymentInProgress,
    savePaymentMethodInProgress: state.paymentMethods.savePaymentMethodInProgress,
    customerUpdateBookingInProgress,
    transactionUpdateBooking,
    listingForMap,
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    stripeCustomerFetched,
    initiateOrderError,
    initiateOrderInProgress,
    fuelTransaction,
    dlGoTripDistanceInProgress,
    isLimitedUser: $isLimitedUser(state),
    fuelPriceByListing: $fuelPriceByListing(state),
    updateBookingTxs: $updateBookingTxsByParentTxId(state, bookingTxId),
    isCancelUpdateBookingsPending: $isCancelUpdateBookingsPending(state),
    isCancelUpdateBookingsError: $isCancelUpdateBookingsError(state),
    isCancelUpdateBookingByAdminPending: $isCancelUpdateBookingByAdminPending(state),
    isAcceptUpdateBookingByAdminPending: $isAcceptUpdateBookingByAdminPending(state),
    distanceFromPickUp,
    userLocation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: (transactionId, userObj = null, process = null) => dispatch(acceptSale(transactionId, userObj, process)),
    onDeclineSale: (transactionId, process) => dispatch(declineSale(transactionId, process)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    useInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
    onFetchCancelUpdateBooking: params => dispatch(fetchCancelUpdateBooking(params)),
    onTransit: (
      transactionId,
      transition,
      cancelData = {},
      tripPhotos = null,
      certificate = null,
      isDrivelahGo = null,
      isStartCar = null,
      isLockCar = null,
      isUnlockCar = null,
      isLocateCar = null,
      isDlGoV3 = null,
      isStopCar = null,
      cleanlinessScore = null,
      isDropOff = null
) => dispatch(transit(transactionId, transition, cancelData, tripPhotos, certificate, isDrivelahGo, isStartCar, isLockCar, isUnlockCar, isLocateCar, isDlGoV3, isStopCar, cleanlinessScore, isDropOff)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onResetCode: () => dispatch(resetCode()),
    onHandlePaymentFuel: (params) => dispatch(handlePaymentFuelAfterFail(params)),
    onUploadInteriorPhotoToMetadata: (transactionId, tripPhotos = null,) => dispatch(uploadInteriorPhotoToMetadata(transactionId, tripPhotos)),
    onAddFuelInclusionAndSaveCard: params => dispatch(addFuelInclusionAndSaveCard(params)),
    onFetchTransaction: (id, txRole) => dispatch(fetchTransaction(id, txRole)),
    onInitiateOrder: (params, odometerStartValue, odometerEndValue, dlGoTripDistance, transactionId) => dispatch(initiateOrder(params, odometerStartValue, odometerEndValue, dlGoTripDistance, transactionId)),
    fetchStripeCustomer: () => dispatch(stripeCustomer()),
    onHandleCardPayment: params => dispatch(handleCardPayment(params)),
    onConfirmPayment: params => dispatch(confirmPayment(params)),
    onEstimateFuelBreakdown: params => dispatch(estimateFuelBreakdown(params)),
    onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
      dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
    fetchFuelChildTransaction: id => dispatch(fetchFuelChildTransaction(id)),
    getDlGoDistance: txId => dispatch(getDlGoTripDistance(txId)),
    fuelNotificationWithoutCharge: txId => dispatch(fuelNotificationWithoutCharge(txId)),
    onUpdateListingOdometerData: (lId, val) => dispatch(updateListingOdometerData(lId, val)),
    onFuelNotificationFor500: txId => dispatch(fuelNotificationFor500(txId)),
    onCancelUpdateBookingByAdmin: tx => dispatch(cancelUpdateBookingByAdmin(tx)),
    onAcceptUpdateBookingByAdmin: tx => dispatch(acceptUpdateBookingByAdmin(tx)),
    getDistanceFromPickUp: listingLocation => dispatch(getDistanceFromPickUp(listingLocation))
  };
};

const TransactionPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = params => {
  return loadData(params);
};

TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
